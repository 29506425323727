import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../components/defaultSnippets/Card';
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function AttendanceAddStaff() {
    const [students, setStudents] = useState([]);
    const [searchData, setSearchData] = useState({});
    const studentData = useSelector(selectStudentStatus);
    const [formData, setFormData] = useState({});
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [attendanceData, setAttendanceData] = useState({});
    const navigate = useNavigate();

    

    const fetchStaffs = async () => {
        const findid = toast.loading("Fetching Students data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.get('/api/attendancestaffsearchall', {
                params: searchData // Pass searchData as query parameters
            });
            setStudents(res.data.results);
            toast.dismiss(findid); // Dismiss the loading toast when data is fetched successfully
        }
        catch (err) {
            // If request fails
            console.log(err.response);
            if (err.response && err.response.status === 422) {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            } else {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            }
        }
    }

    useEffect(() => {
        if (!searchData.att_date) {
            setIsButtonEnabled(false);
        } else {
            setIsButtonEnabled(true);
        }
    }, [searchData]);

    const handleChange = (event, studentId, session) => {
        const { value } = event.target;
        // Update attendance data for the selected session and student
        setAttendanceData(prevAttendanceData => ({
            ...prevAttendanceData,
            [`${studentId}_session${session}`]: value
        }));

    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [name]: value,
        }));
    };

        const handleSearch=(e) => {
    e.preventDefault();
    console.log(searchData);
    fetchStaffs()
}

    const handleDateChange = (selectedDates, dateField) => {
        const selectedDate = selectedDates[0];
        let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
        formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [dateField]: formattedDate,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle submission of attendance data
        console.log(attendanceData);
        const dataToSubmit = {
        ...attendanceData,
        ...searchData
    };

    // Handle submission of attendance data
    console.log(dataToSubmit);
    SendData(dataToSubmit);
     //   SendData()
    };

    const SendData = async(dataToSubmit) =>{
     const id =  toast.loading("Saving attendance data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post('/api/staffattendanceadd', dataToSubmit);

        // If request is successful
        console.log(res.data);
        toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/studentattendance');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }

    return (
        <>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Add Staff Attendance</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form >
                                <Row className="align-items-center">
                                    
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <Flatpickr
                                                id="att_date"
                                                name="att_date"
                                                value={formData.att_date}
                                                onChange={(selectedDates) =>
                                                    handleDateChange(selectedDates, 'att_date')
                                                }
                                                options={{
                                                    dateFormat: 'd-m-Y', // Customize date format
                                                    altInput: true, // Enable alternate input
                                                    altFormat: 'd-m-Y', // Alternate input format
                                                    maxDate: new Date(),
                                                }}
                                                className="form-control" // Add any additional CSS classes here
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="1">
                                        <div className="text-end">
                                            <Button type="button" id="searchbutton" variant="primary" onClick={handleSearch} disabled={!isButtonEnabled}>Proceed</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <h2 className='text-start'>Attendance Input Form</h2>
                                <Row className='mb-2' style={{backgroundColor:'#2664CF',color:'#fff',padding:'4px'}}>
                                    <Col sm="3">
                                            <b>Student Name</b>
                                    </Col>
                                    <Col sm="1"><b>Session 1</b></Col>
                                    <Col sm="1"><b>Session 2</b></Col>
                                   

                                </Row>
                                {students.map((student, studentIndex) => (
                                    <div key={student.id}>
                                       
                                        <Row className='mb-3' style={{borderBottom:'1px solid grey',paddingBottom:'6px'}}>
                                             <Col sm="3">
                                        <h6>{student.first_name}</h6>
                                        </Col>
                                            {[...Array(2)].map((_, sessionIndex) => (
                                                <Col key={sessionIndex} sm="1">
                                                    <Form.Group>
                                                        {/* <label htmlFor={`session_${studentIndex}_${sessionIndex + 1}`}>Session {sessionIndex + 1}</label> */}
                                                        <select
                                                            id={`session_${studentIndex}_${sessionIndex + 1}`}
                                                            name={`session_${studentIndex}_${sessionIndex + 1}`}
                                                            onChange={(event) => handleChange(event, student.id, sessionIndex + 1)}
                                                            style={{ backgroundColor: attendanceData[`${student.id}_session${sessionIndex + 1}`] === 'present' ? 'lightgreen' : attendanceData[`${student.id}_session${sessionIndex + 1}`] === 'absent' ? 'lightcoral' : 'inherit' }}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="present">Present</option>
                                                            <option value="absent">Absent</option>
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                                <Button type="submit">Submit Attendance</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default AttendanceAddStaff;
