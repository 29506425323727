import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../../components/defaultSnippets/Card';
import { useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";

function BillingReportLandingMain() {
    
    const [courses, setCourses] = useState('');
    const [searchData, setSearchData] = useState({
        billtype: '', courseid: '', current_year: '', academic_year: '', studentid: ''
    });
    const currentYear = new Date().getFullYear(); // Get the current year
  const startYear = 2020; // Define the start year for the dropdown
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = [];
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i);
  }

    // Function to generate options for past four years
const generateYearOptions = () => {
 
   
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(1);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};
  const handleyearChange = (event) => {
    setSelectedYear(event.target.value); // Update the selected year
  };


    const fetchCourses = async () => {
        const findid = toast.loading("Fetching Courses data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.get('/api/attendancereportmain');
            setCourses(res.data.results);
            toast.dismiss(findid); // Dismiss the loading toast when data is fetched successfully
        }
        catch (err) {
            console.log(err.response);
            toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    const handleDateChange = (selectedDates, dateField) => {
        const selectedDate = selectedDates[0];
        let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
        formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [dateField]: formattedDate,
        }));
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        console.log(name+"->"+value)
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [name]: value,
        }));
    };

    

    const handleSearch = (e) => {
        e.preventDefault();
       
        if (searchData.courseid !== ""  && searchData.current_year !== "" && searchData.academic_year!="") {
            if(searchData.billtype!="" && searchData.studentid==""){
                toast.error("Please enter student id", {
                    position: "bottom-right",
                    autoClose: 3000,
                    theme: "dark",
                });
                return;
            }
            else
            {
                fetchReport();
            }
           
        } else {
            // Handle missing fields
            toast.error("Please complete all required fields!", {
                position: "bottom-right",
                autoClose: 3000,
                theme: "dark",
            });
        }
    };

    const fetchReport = async () => {
        const findid = toast.loading("Fetching report data...", {
            position: "bottom-right",
            autoClose: 3000,
            theme: "dark",
        });

        try {
            const res = await axios.get('/api/billingreportgenerate', {
                params: searchData // Pass searchData as query parameters
            });
            toast.dismiss(findid);
        } catch (err) {
            console.log(err.response);
            toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    return (
        <>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Generate Billing Report</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="align-items-center">
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="billtype"
                                                id="billtype"
                                                className="form-control"
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Type</option>
                                                <option value='bus'>Bus</option>
                                                <option value='hostel'>Hostel</option>
                                                <option value='academic'>Academic</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    
                                    <Col sm="3">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="courseid"
                                                id="courseid"
                                                className="form-control"
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Course</option>
                                                {Object.entries(courses).map(([key, course], index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="1">
                                            <Form.Group className="mb-3 mb-sm-0">
                                            {/* <Form.Label>Current Year:</Form.Label> */}
                                            <select name="current_year" id="current_year" className="selectpicker form-control" data-style="py-0"  value={searchData.current_year} onChange={handleSearchChange}>
                                            <option value="">Year</option>
                                            <option>I</option>
                                            <option>II</option>
                                            <option >III</option>
                                            <option >IV</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                     <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="academic_year"
                                                id="academic_year"
                                                className="selectpicker form-control"
                                                data-style="py-0"
                                                value={searchData.academic_year}
                                                onChange={handleSearchChange}
                                            >
                                                <option value="">Academic Year</option>
                                                {generateYearOptions()}
                                            </select>
                                         </Form.Group>
                                    </Col>
                                    {/* Conditionally render att_date or att_month based on att_type */}
                                   

                                     

                                         <Col md="2">
                                            <Form.Group as={Row} className="mb-3 mb-sm-0">
                                                {/* <Form.Label column sm="2" htmlFor="email" className="control-label text-start">Student ID:</Form.Label> */}
                                                <Col sm="12">
                                                    <Form.Control name="studentid" id="studentid" placeholder="Enter Student ID" value={searchData.studentid} onChange={handleSearchChange} required />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    
                                    <Col sm="1">
                                        <div className="text-end">
                                            <Button type="button" id="searchbutton" variant="primary" onClick={handleSearch}>Generate</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default BillingReportLandingMain;
