import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from './axios';
import { Modal,Button } from 'react-bootstrap';
import { persistor } from '../store/store';

const TokenChecker = () => {
  const navigate = useNavigate();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [countdown, setCountdown] = useState(60); // Initial countdown value in seconds
  const [countdownInterval, setCountdownInterval] = useState(null);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get('/api/check-token');
        const expiresIn = response.data.expires_in;
console.log(response.data);
        // Check if the token will expire in the next 60 seconds
        if (expiresIn <= 1) {
          setConfirmationOpen(true);
          // Start the countdown timer
          startCountdown(60);
        }
      } catch (error) {
        console.error('Token is expired or invalid');
        localStorage.setItem('token', '')
        // Open the confirmation dialog when the token is expired or invalid
        persistor.purge();
        navigate('/');
      }
    };

    // Check token validity every minute
    const intervalId = setInterval(checkTokenValidity, 60 * 1000);

    return () => {
      clearInterval(intervalId);
      // Clear the countdown interval when the component is unmounted
      clearInterval(countdownInterval);
    };
  }, [navigate, countdownInterval]);

  const startCountdown = (initialSeconds) => {
    let seconds = initialSeconds;

    const intervalId = setInterval(() => {
      setCountdown(seconds);
      seconds--;

      if (seconds < 0) {
        clearInterval(intervalId);
        // Close the confirmation dialog and refresh the token when the countdown reaches 0
        handleCloseConfirmation();
      }
    }, 1000);

    // Store the countdown interval ID
    setCountdownInterval(intervalId);
  };

  const handleCloseConfirmation = () => {
    // Close the confirmation dialog without refreshing the token
    setConfirmationOpen(false);
    persistor.purge();
    navigate('/');
  };

  const handleConfirmRefresh = async () => {
    // Close the confirmation dialog and refresh the token
    setConfirmationOpen(false);

    try {
      // Clear the countdown interval
      clearInterval(countdownInterval);

      // Refresh the token
      const response = await axios.get('api/refresh-token');
      console.log(response.data);
      localStorage.setItem('token', response.data.token);

      console.log('Token refreshed successfully!');
    } catch (error) {
      console.error('Failed to refresh token', error);
      // Handle the error (e.g., redirect to login page)
      persistor.purge();
      navigate('/');
    }
  };

  return (
    <>
      <Modal show={isConfirmationOpen} onHide={handleCloseConfirmation}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your token is about to expire in {countdown} seconds. Do you want to refresh it?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleCloseConfirmation}>
          Logout
        </Button>
        <Button variant="success" onClick={handleConfirmRefresh}>
          Refresh
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default TokenChecker;
