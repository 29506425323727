import React,{useState,useEffect} from 'react'
import { Form, Nav , Tab, Row, Col, InputGroup,Button } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectStudentStatus } from '../Students/studentSlice';
const SubjectsAdd = () => {
 const [formData, setFormData] = useState({});
 const[staffData,setStaffData] = useState([]);
 const studentData=useSelector(selectStudentStatus);
 console.log(studentData.coursenames.data.results);
  const navigate = useNavigate();

   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
SendData()
    // Handle form submission with formData
  };
   const fetchStaffs = async() =>{
     const id =  toast.loading("Fetching Staffs...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  await axios.get('/api/stafflistacademic')
    .then(res=> {
     // console.log(res.data.results);
      // const jsonData = JSON.stringify(res.data.results);
    // dispatch(setfeesdetails(res.data.results));
      // localStorage.setItem('feeslist',jsonData);
      toast.update(id, { render: "Successfull", type: "success", isLoading: false, autoClose: 1000,theme: "light"}); setStaffData(res.data.results);
      console.log(staffData);
  })
    .catch(err=>console.log(err))
  }

useEffect(() => {
  fetchStaffs()
 },[])



  const SendData = async() =>{
     const id =  toast.loading("Saving data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post('/api/subjectadd', formData);

        // If request is successful
        console.log(res.data);
        toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/subjectmanagment');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
                <Row>
                   
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Add new Subject</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                              
                                <Form className="form-horizontal" onSubmit={(e)=>handleSubmit(e)}>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="email">Course Name:</Form.Label>
                                        <Col sm="9">
                                        
                                       
                                          <select
                                                name="subjectcoursekey"
                                                id="subjectcoursekey"
                                                className="selectpicker form-control col-md-4"
                                               onChange={handleChange}
                                            >
                                              <option>Select Course</option>
                                                {studentData.coursenames.data.results.map((course, index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                ))}
                                          </select>
                                   
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="email">Course Year:</Form.Label>
                                        <Col sm="9">
                                        
                                       
                                         <select name="subjectyear" id="subjectyear" className="selectpicker form-control" data-style="py-0" onChange={handleChange}>
                                                <option value=''>Select Year</option>
                                                <option>I</option>
                                                <option>II</option>
                                                <option>III</option>
                                                <option>IV</option>
                                            </select>
                                   
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="subjectcode">Subject Name:</Form.Label>
                                        <Col sm="9">
                                        <Form.Control name="subjectcode"  placeholder="Enter Subject Code" value={formData.subjectcode} onChange={handleChange} required/>
                                        </Col>
                                    </Form.Group>
                                     <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="subjectname">Subject Name:</Form.Label>
                                        <Col sm="9">
                                        <Form.Control name="subjectname"  placeholder="Enter Subject Name" value={formData.subjectname} onChange={handleChange} required/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="email">Handled By:</Form.Label>
                                        <Col sm="9">
                                        
                                       
                                          <select
                                                name="subjecthandledby"
                                                id="subjecthandledby"
                                                className="selectpicker form-control col-md-4"
                                               onChange={handleChange}
                                            >
                                              <option>Select Staff</option>
                                                {staffData.map((staff, index) => (
                                                    <option key={index} value={staff.id}>
                                                        {staff.first_name}
                                                    </option>
                                                ))}
                                          </select>
                                   
                                        </Col>
                                    </Form.Group>
                                  
                                    <Form.Group className="form-group text-end">
                                        <Button type="submit" variant="btn btn-primary">Submit</Button>{' '}
                                        <Link to={'/subjectmanagment'}><Button type="button" variant="btn btn-danger">cancel</Button></Link>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Col>
                </Row>
            </div>
  );
}

export default SubjectsAdd;
