import React from 'react'
const FooterL = () => {
    return (
        <footer style={{ marginTop: 'auto', padding: '0px', textAlign: 'center', fontFamily:'conthrax',fontSize:'14px', position:'relative',left:0,bottom:0 }}>
            <div className="footer-body">
               
               
                  
      <p>&copy; A.R. Engineering College. ALL RIGHTS RESERVED. {new Date().getFullYear()} </p>

               
            </div>
        </footer>
    )
}

export default FooterL
