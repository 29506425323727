import React, {useState, useContext,memo,Fragment} from 'react'
import { Link, useLocation} from 'react-router-dom'
import {Accordion, useAccordionButton, AccordionContext} from 'react-bootstrap'
import { useEffect } from 'react';
import axiosapi from '../../api/axios';
import {Image} from 'react-bootstrap';
import { useDispatch,useSelector } from 'react-redux';
import { setsidemenu,selectStatus,setcurrentmodulename } from '../login/loginSlice';

function CustomToggle({ children, eventKey, onClick }) {

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({state: !active, eventKey: eventKey}));

    const isCurrentEventKey = activeEventKey === eventKey;
  
    return (
      <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
        decoratedOnClick(isCurrentEventKey)
      }}>
        {children}
      </Link>
    );
  }

const VerticalNav = memo((props) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState('');
  const [itemsArray, setItemsArray] = useState([]);
  const dispatch=useDispatch()
  const status=useSelector(selectStatus)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // if(Object.keys(status.sidemenu).length === 0)
        // {
          //alert("api request going");
          const response = await axiosapi.get('api/getsidemenus');
          console.log(response.data);
          setItemsArray(response.data);
          dispatch(setsidemenu(response.data));
        // }
        // else
        // {
        //  // alert("no api request");
        //   setItemsArray(status.sidemenu)
         
        // }
        
        // Handle the response as needed
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  

  // location
  let location = useLocation();

   const renderSubmenus = (submenus) => {
    return (
      <ul className="sub-nav">
        {submenus.map((submenu) => (<>
          <li className="nav-item" key={submenu.id}>
            <Link className={`${location.pathname === `/${submenu.menu_slug}` ? 'active' : ''} nav-link`} to={`/${submenu.menu_slug}`}>
                <Image src={submenu.menu_icon} alt={submenu.menu_name} style={{opacity:0.8, width:20 }} className="rounded img-fluid me-0"/>
                
                
              <span className="item-name nav-item-name me-2 text-start">{submenu.menu_name}</span>
            </Link>
          </li>
          <hr className="hr-horizontal"/>
        </>))}
      </ul>
    );
  };

  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        {/* <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
           
            <span className="mini-icon">T</span>
          </Link>
        </li> */}
        
<li className={`${location.pathname === '/dashboard' ? 'active' : ''} nav-item `}>
                    <Link className={`${location.pathname === '/dashboard' ? 'active' : ''} nav-link `} aria-current="page" to="/dashboard" onClick={() => {}}>
                        <i className="icon">
                            <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z" fill="currentColor"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z" fill="currentColor"></path>
                            </svg>
                        </i>
                        <span className="item-name nav-item-name me-2 text-start">Dashboard</span>
                    </Link>
                </li>
                <li><hr className="hr-horizontal"/></li>
                <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
           <span className="default-icon" style={{}}>Data Collection Forms</span>
           
          </Link>
        </li>
  {itemsArray.map((item) => (
  <Fragment key={item.id}>
    <Accordion.Item as="li" eventKey={item.menu_slug} key={item.id} bsPrefix={`nav-item ${active === 'menustyle' ? 'active' : ''} `} onClick={() => setActive('menustyle')}>
      <CustomToggle eventKey={item.menu_slug} onClick={(activeKey) => setActiveMenu(activeKey)}>
        <div className="d-flex align-items-center justify-content-between w-100" style={{marginRight:'5px'}}>
          <div className="d-flex align-items-center">
            {/* <Image src={item.menu_icon} alt={item.menu_name} style={{backgroundColor:'#0F86CF', opacity:0.8, width:40 }} className="rounded img-fluid me-2" /> */}
            <span className="item-name nav-item-name me-2 text-start">{item.menu_name}</span>
          </div>
          <i className="right-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </i>
        </div>
      </CustomToggle>
    </Accordion.Item>
    <li><hr className="hr-horizontal"/></li>
    <Accordion.Collapse eventKey={item.menu_slug}>
      {renderSubmenus(item.submenus)}
    </Accordion.Collapse>
  </Fragment>
))}


      
      </Accordion>
    </Fragment>
  );
});

export default VerticalNav;
