import React,{useState} from 'react'
import { Form, Nav , Tab, Row, Col, InputGroup,Button } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
const CourseAdd = () => {
 const [formData, setFormData] = useState({
    coursename: '',
    // Add more fields as needed
  });

  const navigate = useNavigate();

   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
SendData()
    // Handle form submission with formData
  };

  const SendData = async() =>{
     const id =  toast.loading("Saving data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post('/api/courseadd', formData);

        // If request is successful
        console.log(res.data);
        toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/coursemanagement');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
                <Row>
                   
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Add new Course</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                              
                                <Form className="form-horizontal" onSubmit={(e)=>handleSubmit(e)}>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="email">Course Name:</Form.Label>
                                        <Col sm="9">
                                        <Form.Control name="coursename"  placeholder="Enter Course Name" value={formData.coursename} onChange={handleChange} required/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">Course Duration:</Form.Label>
                                        <Col sm="9">
                                        <Form.Control name="course_duration"  placeholder="Enter Course Duration" value={formData.course_duration} onChange={handleChange} required/>
                                        </Col>
                                    </Form.Group>
                                  
                                    <Form.Group className="form-group text-end">
                                        <Button type="submit" variant="btn btn-primary">Submit</Button>{' '}
                                        <Link to={'/coursemanagement'}><Button type="button" variant="btn btn-danger">cancel</Button></Link>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Col>
                </Row>
            </div>
  );
}

export default CourseAdd;
