import logo from './logo.svg';
import './App.css';
import "./assets/scss/hope-ui.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/customizer.scss"
import { useDispatch } from 'react-redux';
function App({children}) {
   const dispatch = useDispatch()
  return (
    <div className="App">
      {children}
    </div>
  );
}

export default App;
