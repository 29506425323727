
import React, { useEffect } from 'react'
import { Row,Col,Table,Form,Button,Modal } from 'react-bootstrap'
import Card from '../../components/defaultSnippets/Card'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { FaEdit,FaTrashAlt  } from 'react-icons/fa';
import axios from '../../api/axios'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
// import { setfeesdetails } from './feeSlice';
const SubjectsLandingMain = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
const[data, setData] = useState([]);
const[deleteId,setDeleteId]=useState('')
const [showConfirm, setShowConfirm] = useState(false);
  const dispatch=useDispatch()
const handleClose = () => setShowConfirm(false);


  const fetchData = async() =>{
     const id =  toast.loading("Fetching data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  await axios.get('/api/subjectlist')
    .then(res=> {
      console.log(res.data.results);
      // const jsonData = JSON.stringify(res.data.results);
    // dispatch(setfeesdetails(res.data.results));
      // localStorage.setItem('feeslist',jsonData);
      toast.update(id, { render: "Successfull", type: "success", isLoading: false, autoClose: 1000,theme: "light"}); setData(res.data.results);
  })
    .catch(err=>console.log(err))
  }
 useEffect(() => {
  fetchData()
 },[])

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter the data based on the search term
  const filteredData = data.filter((item) => {
    //console.log(item)
    return (
      item.subjectcoursename.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subjecthandledbyname.toLowerCase().includes(searchTerm.toLowerCase())
    
    );
  });

  // Recalculate total entries and total pages based on filtered data
  const totalEntries = filteredData.length;
  const totalPages = Math.ceil(totalEntries / itemsPerPage);

  // Slice the filtered data for the current page
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const firstEntryIndex = Math.min((currentPage - 1) * itemsPerPage + 1, totalEntries);
  const lastEntryIndex = Math.min(currentPage * itemsPerPage, totalEntries);
  const showconfirm=(id)=>{
    setShowConfirm(true)
    setDeleteId(id);
   // handleDelete(id)
  }
  const  handleDelete=async (id)=>
  {
    const deleteid =  toast.loading("Deleting data...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
await axios.delete('/api/subjectdelete/'+id)
.then(res=> {
  handleClose()
const newCourseData=data.filter((item)=>{
  return(item.id!==id)
 
})
setData(newCourseData);
toast.update(deleteid, { render: "Successfull", type: "success", isLoading: false, autoClose: 1000,theme: "light"}); 
})
.catch(err=>console.log(err))
}
  
  return (
    <>
      <ToastContainer />
    <div style={{height:'780px'}}>
        <Row style={{ marginRight: '0', marginLeft: '0', padding: '0' }}>
           <Col sm={12} style={{ padding: '0', textAlign:'end',marginBottom:'10px' }}>
              <Link to={'addsubject'}><button type="button" class="btn btn-primary"><FaPlus size={16}/>&nbsp;Add Subject</button></Link>
            </Col>
          </Row>
        <Row style={{ marginRight: '0', marginLeft: '0', padding: '0' }}>
          <Col sm={12} style={{ padding: '0' }}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={9} className="d-flex align-items-center">
                    {/* <Col sm={3} className="mb-3 text-start">
                      <Button variant="primary"> <BsPlus size={26} />Add Course</Button>
                    </Col> */}
                    {/* <Col sm={1} className="mb-3"></Col> */}
                    <Col sm={2} className="mb-3 d-flex align-items-center">
                      <label className="mr-2">Rows:</label>
                      <Form.Control
                        as="select"
                        value={itemsPerPage}
                        onChange={(e) => {
                          setItemsPerPage(parseInt(e.target.value));
                          setCurrentPage(1);
                        }}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                      </Form.Control>
                    </Col>
                  </Col>
                  <Col sm={3} className="text-end">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Col>
                </Row>
                <div className="table-responsive border-bottom my-0 mt-0">
                  <Table responsive  id="datatable" className="text-start" size="md" data-toggle="data-table">
                    <thead className='text-start'>
                      <tr>
                        <th style={{}}>Course Name</th>
                        <th style={{}}>Year</th>
                        <th style={{}}>Subject Code</th>
                        <th style={{}}>Subject name</th>
                        <th style={{}}>handled By</th>
                        <th  style={{}}>Created On</th>
                        <th  style={{}}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.length === 0 ? (
                        <tr className='text-center'>
                          <td colSpan="3"  style={{color:'grey'}}>No Data available</td>
                        </tr>
                      ) : (
                        currentItems.map((item, index) => (
                          <tr key={index}>
                            <td style={{ padding: '8px' }}>{item.subjectcoursename}</td>
                            <td style={{ padding: '8px' }}>{item.subjectyear}</td>
                            <td style={{ padding: '8px' }}>{item.subjectcode}</td>
                            <td style={{ padding: '8px' }}>{item.subjectname}</td>
                            <td style={{ padding: '8px' }}>{item.subjecthandledbyname}</td>
                            <td style={{ padding: '8px' }}>{item.created_at}</td>
                            <td style={{ padding: '8px' }}><Link to={`editsubject/${item.id}`}><button type="button" class="btn btn-sm"><FaEdit size={19} style={{color:'blue'}}/></button></Link>&nbsp;&nbsp;<button type="button" class="btn btn-sm" onClick={()=>showconfirm(item.id)}><FaTrashAlt size={19} style={{color:'red'}}/></button></td>
                            
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between align-items-center">
                    Showing {firstEntryIndex} to {lastEntryIndex} of {totalEntries}
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                          Previous
                        </button>
                      </li>
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => paginate(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal show={showConfirm}  onHide={handleClose} backdrop="static" keyboard={false} centered={true}>
            <Modal.Header closeButton>
            <Modal.Title as="h5">Delete Subject</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Are you sure?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="danger" onClick={(e)=>handleDelete(deleteId)}>Delete</Button>
            </Modal.Footer>
        </Modal>
        
    </div>
       
      
    </>
  );
};


export default SubjectsLandingMain
