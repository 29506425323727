import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../../api/axios'

export const loginUser = createAsyncThunk(
  "auth/login",
  async (data,{rejectWithValue }) => {
    try 
    {
        const response = await axios.post("/api/login", data);
        if (response.status === 200) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('username', response.data.user.name);
           // localStorage.setItem('permissions', response.data.permissions);
           
            return { status: response.status, data: response.data };
        }
    } 
    catch (error) {
      if (error.response && error.response.status === 401) 
      {
        // Handle 401 unauthorized error
         localStorage.setItem('token', '');
         localStorage.setItem('username', '');
        return rejectWithValue({status: error.response.status, data: error.response.data.message});
      } else {
        // Handle other errors
        localStorage.setItem('token', '');
        localStorage.setItem('username', '');
        return rejectWithValue({status: error.response.status, data: error.response.data.message});
      }
    }
  }
);

const initialState = {
  isAuthenticated: 0,
  user:null,
  token:null,
  loadingstatus:false,
  error:null,
  clientlogo:null,
  currentmodulename:null,
  sidemenu:{}
};

const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state,action) => {
      state.isAuthenticated = 12;
      state.user = action.payload
    },
    logout: (state) => {
      return initialState; // Reset state to initial state upon logout
    },
    setsidemenu: (state,action) => {
      state.sidemenu= action.payload
      console.log("sidemenudata="+action.payload)
    },
    setcurrentmodulename: (state,action) => {
      state.currentmodulename= action.payload
     // console.log("currentmodulename="+action.payload)
    }
  },
  extraReducers: (builder)=>{
   builder.addCase(loginUser.pending, (state) => {
  state.loadingstatus = true; // <-- Update here
  state.user = null;
  state.error = null;
});
builder.addCase(loginUser.fulfilled, (state, action) => {
  state.loadingstatus = false; // <-- Update here
  state.user = action.payload;
  state.error = null;
  state.isAuthenticated = 1;
  
    console.log(action.payload.data.user.logosrc);
    state.clientlogo = action.payload.data.user.logosrc;
  
});
builder.addCase(loginUser.rejected, (state, action) => {
  state.loadingstatus = false; // <-- Update here
  state.user = null;
  console.log(action.payload.data);
  state.error = action.payload;
});


  }
});
export const selectStatus=(state)=>state.auth;
export const { login, logout,setsidemenu,setcurrentmodulename } = loginSlice.actions;

export default loginSlice.reducer; // Ensure to export the loginSlice reducer
