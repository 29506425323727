import React, { memo, Fragment, useEffect, useRef, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';
import topHeader from '../../assets/images/dashboard/top-header.png';
import topHeader1 from '../../assets/images/dashboard/top-header1.png';
import topHeader2 from '../../assets/images/dashboard/top-header2.png';
import topHeader3 from '../../assets/images/dashboard/top-header3.png';
import topHeader4 from '../../assets/images/dashboard/top-header4.png';
import topHeader5 from '../../assets/images/dashboard/top-header5.png';
import tracerlogo from '../../assets/images/auth/logo_tracer.png'
import {Image} from 'react-bootstrap';
import ClientLogo from './ClientLogo';
import { selectStatus,setcurrentmodulename } from '../login/loginSlice';
import { setcurrentsubmodulename,resetcurrentsubmodulename,selectCourseStatus } from '../Courses/courseSlice';
import { useDispatch,useSelector } from 'react-redux';

const SubHeaderL = memo((props) => {
  const location = useLocation();
  const parentRef = useRef(null);
  const hipaaStatus=useSelector(selectCourseStatus)
  const status=useSelector(selectStatus)
  const dispatch=useDispatch()
  useEffect(() => 
  {
    AOS.refresh();
    if(Object.keys(status.sidemenu).length !== 0)
    {
     const splitdata=location.pathname.substring(1).split('/');

    // alert(splitdata.length)
     const menuSlugToFind = location.pathname.split("/")[1];
     const menuModuleName = findMenuModuleNameBySlug(menuSlugToFind,status.sidemenu);
    dispatch(setcurrentmodulename(menuModuleName))
     // dispatch(resetcurrentsubmodulename(null))
     if(splitdata.length>1)
     {
      //dispatch(setcurrentsubmodulename(splitdata[1]))
     }
      // if (menuModuleName) {
      //     console.log(`Menu module name for slug '${menuSlugToFind}' is: ${menuModuleName}`);
      // } else {
      //     console.log(`No menu module name found for slug '${menuSlugToFind}'`);
      // }
      // console.log(status.currentmodulename)
    }
    // Set the dynamic value to trigger re-render
   
  }, [location.pathname]);

  useEffect(() => {
    // Initialize AOS on component mount
    AOS.init({
      duration: 800, // Set your desired duration
      delay: 800, // Set your desired delay
    });
    AOS.refreshHard();
  }, []);

 function findMenuModuleNameBySlug(menuSlug,menuData) {
    // Remove leading slash if present
    menuSlug = menuSlug.startsWith('/') ? menuSlug.substring(1) : menuSlug;
    // Loop through the menu data array
   // alert(menuSlug)
    for (const menu of menuData) {
        // Check if the current menu has submenus
        if (menu.submenus) {
            // Search for the submenu with the given menu_slug
            const foundSubmenu = menu.submenus.find(submenu => submenu.menu_slug === menuSlug);
            // If found, return the menu_modulename
            if (foundSubmenu) {
                return foundSubmenu.menu_modulename;
            }
        }
    }
    // Return null if no match is found
    return null;
}

  return (
    <Fragment key={status.currentmodulename}>
      <div className="iq-navbar-header">
        <Container fluid className=" iq-container">
          <Row style={{paddingRight:'10px', paddingLeft:'10px'}}>
            <Col md="12">
              <div
                className="d-flex justify-content-between flex-wrap"
                data-aos="fade-out"
                data-aos-delay="100"
              >
                <div style={{marginTop:'0px'}}>
                  <h1 ref={parentRef} style={{ fontFamily: 'conthrax' }}>
                    {props.valueFromRoute? props.valueFromRoute:status.currentmodulename}
                  </h1>
                  <p style={{marginTop:'2px', textAlign:'left',fontSize:'20px'}}><span>{hipaaStatus.currentsubmodulename}</span></p>
                </div>
                
              </div>
            </Col>
          </Row>
        </Container>
        <div className="iq-header-img" style={{height:'80px', borderRadius:'0px'}}>
          <img src={topHeader} alt="header" className="theme-color-default-img img-fluid w-100 animated-scaleX"/>
         
        </div>
      </div>
    </Fragment>
  );
});

export default SubHeaderL;
