import React from 'react'
import tracerlogo from '../../assets/images/auth/logo_tracer.png'
import { Image } from 'react-bootstrap'
const Logo = (props ) => {
    
    return (
        <>
       <Image className='tracerlogo' src={tracerlogo} style={{width:'240px'}}></Image>
         </>
        )
    }
    
    export default Logo
