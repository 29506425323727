import React,{useState,useEffect,useRef} from 'react'
import { Form, Nav , Tab, Row, Col, InputGroup,Button ,Image } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link, useParams,Navigate } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import avatars1 from '../../assets/images/avatars/01.png'
import avatars2 from '../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../assets/images/avatars/avtar_5.png'
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import {selectStatus} from '../login/loginSlice'
 import { checkPermission } from '../../components/Snippets/utils'
 import { redirect } from 'react-router-dom';
const StaffEditSalary = () => {
    const {id}=useParams()
     const permissions=useSelector(selectStatus)
     const [userPermissions, setUserPermissions] = useState(permissions.user.data.permissions);
//alert(id);
const[isLoading, setIsLoading]=useState(true);
const [formData, setFormData] = useState({});
const [initialSalaryData, setInitialSalaryData] = useState({epfhs:0});
const [ salaryData, setSalaryData] = useState({epfhs:0})
const [citiesForState, setCitiesForState] = useState([]);
const[imagePreview,setImagePreview]=useState('');
const studentData=useSelector(selectStudentStatus);
const [selectedYear, setSelectedYear] = useState('');
  const[duration,setDuration] = useState('');
const fileInputRef = useRef(null);

const fetchCourse = async () => {
    try {
        const res = await axios.get('/api/staff/' + id);
        setIsLoading(false);
        setFormData(res.data.staff);
        if(res.data.staff.salary!==null)
        {
setSalaryData(res.data.staff.salary);
        }
        
        setInitialSalaryData(res.data.staff.salary);
        if (res.data.staff && res.data.staff.profilePic) {
            // Set the imagePreview state with the absolute URL of the profile picture
            const imagePath = process.env.REACT_APP_API_BASE_URL+'/'+res.data.staff.profilePic;
            setImagePreview(imagePath);
        }
        // Pre-select state and city based on the fetched data
        handleStateChange({ target: { value: res.data.staff.state } });
      //   handleyearChange({ target: { value: res.data.student.academic_year } });
    } catch (err) {
        console.log(err.response);
    }
};

 

useEffect(() => {
    // Wait for the state to be updated before calling handleCityChange
    fetchCourse()
   if (formData.state && studentData.citynames.data.results.length > 0) {
        const cities = studentData.citynames.data.results.filter(
            (city) => city.state_id === formData.state
        );
        setCitiesForState(cities);
      //   setFormData(prevFormData => ({
      //       ...prevFormData,
      //       city: formData.city // Pre-select city based on fetched data
      //   }));
    }
}, [studentData.citynames.data.results]);
 

  const navigate = useNavigate();

  const handleStateChange = (event) => {
    const selectedStateId = parseInt(event.target.value); // Convert to number
    console.log("Selected State:", selectedStateId);
    setFormData(prevFormData => ({ ...prevFormData, state: selectedStateId }));
    // Filter cities based on the selected state ID
    const cities = studentData.citynames.data.results.filter(
        (city) => city.state_id === selectedStateId
    );
    console.log("Cities for State:", cities);
    setCitiesForState(cities);
};


  const handleCityChange = (event) => {
    const selectedCityId = parseInt(event.target.value);
    setFormData({ ...formData, city: selectedCityId }); // Update formData.city
  };

   const handleCourseChange = (event) => {
    const selectedCourseId = parseInt(event.target.value);
    setFormData({ ...formData, current_program_handled: selectedCourseId }); // Update formData.city
   // Find the course object with the selectedCourseId
   console.log(selectedCourseId);
   console.log(studentData.coursenames.data.results);
  const selectedCourse = studentData.coursenames.data.results.find(course => course.id === selectedCourseId);
  console.log(selectedCourse);
  if (selectedCourse) {
    // Extract the course_duration from the selected course
    const courseDuration = selectedCourse.course_duration;
    // Now you can do whatever you want with the courseDuration, such as setting it in the state
    console.log(courseDuration);
    setDuration(courseDuration);
  }
  };

  // Function to generate options for past four years
const generateYearOptions = () => {
   let temp;
   if(duration===''){
       temp=4;
   }
   else{
       temp=duration;
   }
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(temp);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};
  const handleDateChange = (selectedDates, dateField) => {
    // const selectedDate = selectedDates[0];
    // setFormData({
    //   ...formData,
    //   [dateField]: selectedDate,
    // });
    const selectedDate = selectedDates[0];
    let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
    formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
    setFormData({
        ...formData,
        [dateField]: formattedDate, // Set the formatted date in the formData
    });
  };

  

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setFormData({
//       ...formData,
//       profilePic: file, // Set profilePic in formData to the selected file
//     });
//     console.log(formData);
//   };



const handleChange = (e) => {
  const { name, value } = e.target;

  // Calculate 50% of the fixedsalary if it's being changed
  const fiftyPercent = name === 'fixedsalary' ? value * 0.5 : salaryData.basicsalary;
  const tenPercent = name === 'fixedsalary' ? value * 0.1 : salaryData.specialsalary;
  const fortyPercent = name === 'fixedsalary' ? value * 0.4 : salaryData.hra;
  const totalsalary = name === 'fixedsalary' ? fiftyPercent+tenPercent+fortyPercent: salaryData.totalsalary;
  const epfdedamount = name === 'fixedsalary' ? tenPercent+fiftyPercent : salaryData.epfdeductionamount;
  const twelvepercent = name === 'fixedsalary' ? epfdedamount*0.12:salaryData.epfdeductionpercent;
  const pointsevenfivepercent = name === 'fixedsalary' ? Math.round(totalsalary*0.0075):salaryData.esiamount
  const lessepfsalary = name === 'fixedsalary' ? totalsalary-twelvepercent-pointsevenfivepercent:salaryData.lessepfamount
  //const esipercent = name === 'fixedsalary' ? 

  setSalaryData({
    ...salaryData,
    [name]: value,           // Update the current field
    basicsalary: fiftyPercent, // Set basicsalary if fixedsalary is updated
    specialsalary: tenPercent, // Set specialsalary if fixedsalary is updated
    hra: fortyPercent, // Set hra if fixedsalary is updated
    epfdeductionamount:epfdedamount, // Set epfdeductionamount if fixedsalary is updated
    epfdeductionpercent:twelvepercent,
    totalsalary: totalsalary, // Set totalsalary if fixedsalary is updated
    esiamount:pointsevenfivepercent,
    lessepfamount:lessepfsalary,
    payableamount:lessepfsalary
  });
};

  

  const handleUpdate = (e) => {
    e.preventDefault();

//return false;
     const formDataToSend = new FormData();

    // Append all form data fields to formDataToSend
    // Object.entries(salaryData).forEach(([key, value]) => {
    //     formDataToSend.append(key, value);
    // });

    Object.entries(salaryData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
    });

    // Append the profilePic file to formDataToSend
     formDataToSend.append('staff_id', formData.id);
    formDataToSend.append('_method','PUT');
    console.log("finaldata=");
    console.log(formDataToSend,"tst");
  

//     const formDataToSend = new FormData();
// console.log(formData)
//     // Append all form data fields to formDataToSend
//     Object.entries(formData).forEach(([key, value]) => {
//         formDataToSend.append(key, value);
//     });
//     console.log(formDataToSend);
//     // Append the profilePic file to formDataToSend
//     //formDataToSend.append('profilePic', formData.profilePic);

//     console.log(formDataToSend);
   //  for (const entry of formDataToSend.entries()) {
   //      console.log(entry);
   //  }
   //    return false;
    console.log(formDataToSend);
    SendData(formDataToSend);
    // Handle form submission with formData
  };

  const SendData = async(formDataToSend) =>{
     const loaderid =  toast.loading("Updating data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
   
        const resupdate = await axios.post(`/api/staffsalaryupdate/${id}`, formDataToSend,{
          headers: {
            'Content-Type':'multipart/form-data'
          }
        });

        // If request is successful
        console.log(resupdate.data);
        toast.update(loaderid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        //navigate('/coursemanagement');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (

    <div>
       {checkPermission(userPermissions, 'edit-staff') ? (<>
         <ToastContainer />
         <Row>
               <Col xl="3" lg="4" className="">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Edit Staff Salary</h4>
                        </div>
                     </Card.Header>
                     <Card.Body className="text-start">
                       
                           <Form.Group className="form-group">
                           <div className="profile-img-edit position-relative">
    {imagePreview ? (
        <Image src={imagePreview} alt="profile-preview" className="profile-pic rounded avatar-100" />
    ) : (
        <>
            <Image className="theme-color-default-img profile-pic rounded avatar-100" src={avatars1} alt="profile-pic"/>
            <Image className="theme-color-purple-img profile-pic rounded avatar-100" src={avatars2} alt="profile-pic"/>
            <Image className="theme-color-blue-img profile-pic rounded avatar-100" src={avatars3} alt="profile-pic"/>
            <Image className="theme-color-green-img profile-pic rounded avatar-100" src={avatars5} alt="profile-pic"/>
            <Image className="theme-color-yellow-img profile-pic rounded avatar-100" src={avatars6} alt="profile-pic"/>
            <Image className="theme-color-pink-img profile-pic rounded avatar-100" src={avatars4} alt="profile-pic"/>
        </>
    )}
   
</div>

                             
                           </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="staff_id_number">Staff ID Number (AICTE):</Form.Label>
                                    <Form.Control type="text"  id="staff_id_number" name="staff_id_number" placeholder="" value={formData.staff_id_number} onChange={handleChange} disabled/>
                                 </Form.Group>
                                   <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="previous_education">Designation:</Form.Label>
                                    <Form.Control type="text"  id="designation" name="designation" placeholder="" value={formData.designation} onChange={handleChange} disabled/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="first_name">First Name:</Form.Label>
                                    <Form.Control type="text"  id="first_name" name="first_name" placeholder="First Name" value={formData.first_name} onChange={handleChange} disabled/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="aadhar_id">Aadhar Number:</Form.Label>
                                    <Form.Control type="text"  id="aadhar_id" name="aadhar_id" placeholder="" value={formData.aadhar_id} onChange={handleChange} disabled/>
                                 </Form.Group>
                                   <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="pan_id">PAN Number:</Form.Label>
                                    <Form.Control type="text"  id="pan_id" name="pan_id" placeholder="" value={formData.pan_id} onChange={handleChange} disabled/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="lname">Date of Birth:</Form.Label>
                                    {/* <Form.Control type="text"  id="date_of_birth" name="date_of_birth" placeholder="Last Name" value={formData.date_of_birth} onChange={handleChange}/> */}
                                    <Flatpickr
          id="date_of_birth"
          name="date_of_birth"
          value={formData.date_of_birth}
          onChange={(selectedDates) =>
            handleDateChange(selectedDates, 'date_of_birth')
          }
          options={{
            dateFormat: 'd-m-Y', // Customize date format
            altInput: true, // Enable alternate input
            altFormat: 'd-m-Y', // Alternate input format
          }}
          className="form-control" // Add any additional CSS classes here
          disabled
        />
                                 </Form.Group>
                                 
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="current_program_handled">Course handled:</Form.Label>
                                    {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                     <select
                                        name="current_program_handled"
                                        id="current_program_handled"
                                        className="selectpicker form-control"
                                        value={formData.current_program_handled}
                                        onChange={handleCourseChange}
                                        disabled
                                    >
                                        <option>Select Course</option>
                                        {studentData.coursenames.data.results.map((course, index) => (
                                        <option key={index} value={course.id}>
                                            {course.coursename}
                                        </option>
                                        ))}
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="admission_date">Joining Date:</Form.Label>
                                    {/* <Form.Control type="text"  id="admission_date" name="admission_date" placeholder="" value={formData.admission_date} onChange={handleChange}/> */}
                                    <Flatpickr
                                       id="joining_date"
                                       name="joining_date"
                                       value={formData.joining_date}
                                       onChange={(selectedDates) =>
                                          handleDateChange(selectedDates, 'joining_date')
                                       }
                                       options={{
                                          dateFormat: 'd-m-Y', // Customize date format
                                          altInput: true, // Enable alternate input
                                          altFormat: 'd-m-Y', // Alternate input format
                                          maxDate: new Date(), // Disable future dates
                                       }}
                                       className="form-control" // Add any additional CSS classes here
                                       disabled
                                    />

                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                                                <Form.Label htmlFor="releiving_date">Releiving Date:</Form.Label>
                                                                {/* <Form.Control type="text"  id="graduation_date" name="graduation_date" placeholder="" value={formData.graduation_date} onChange={handleChange}/> */}
                                                                <Flatpickr
                                    id="releiving_date"
                                    name="releiving_date"
                                    value={formData.releiving_date}
                                    onChange={(selectedDates) =>
                                        handleDateChange(selectedDates, 'releiving_date')
                                    }
                                    options={{
                                        dateFormat: 'd-m-Y', // Customize date format
                                        altInput: true, // Enable alternate input
                                        altFormat: 'd-m-Y', // Alternate input format
                                    }}
                                    className="form-control" // Add any additional CSS classes here
                                    disabled
                                    />
                                                            </Form.Group>
                                                          
                                 
                      
                     </Card.Body>
                  </Card>
               </Col>
               <Col xl="9" lg="8">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Edit Staff Salary Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body  className="text-start">
                        <div className="new-user-info">
                            <Form className="form-horizontal" onSubmit={(e)=>handleUpdate(e)}>
                              <div className="row">
  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label className="col-md-4 text-right">EPF/HS:</Form.Label>
      <div className="col-md-8">
        <select name="epfhs" id="epfhs" className="selectpicker form-control" data-style="py-0" value={salaryData.epfhs} onChange={handleChange}>
          <option>Select</option>
          <option>EPF</option>
          <option>NEPF</option>
        </select>
      </div>
    </div>
  </Form.Group>

  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="fixedsalary" className="col-md-4 text-right">Fixed Salary:</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="fixedsalary" name="fixedsalary" placeholder="0" value={salaryData.fixedsalary} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>

  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="basicsalary" className="col-md-4 text-right">Basic (50%):</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="basicsalary" name="basicsalary" placeholder="0" value={salaryData.basicsalary} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>

  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="specialsalary" className="col-md-4 text-right">Special Class (10%):</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="specialsalary" name="specialsalary" placeholder="0" value={salaryData.specialsalary} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>

  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="hra" className="col-md-4 text-right">HRA (40%):</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="hra" name="hra" placeholder="0" value={salaryData.hra} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>

  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="epfdeductionamount" className="col-md-4 text-right">EPF Deduction Amount:</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="epfdeductionamount" name="epfdeductionamount" placeholder="0" value={salaryData.epfdeductionamount} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>

  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="epfdeductionpercent" className="col-md-4 text-right">EPF Deduction (12%):</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="epfdeductionpercent" name="epfdeductionpercent" placeholder="0" value={salaryData.epfdeductionpercent} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>

  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="esiamount" className="col-md-4 text-right">ESI (0.75%):</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="esiamount" name="esiamount" placeholder="0" value={salaryData.esiamount} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>

  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="lessepfamount" className="col-md-4 text-right">Less of EPF Salary:</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="lessepfamount" name="lessepfamount" placeholder="0" value={salaryData.lessepfamount} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>
  <Form.Group className="col-md-12 form-group">
    <div className="row">
      <Form.Label htmlFor="lessepfamount" className="col-md-4 text-right">Payable Salary:</Form.Label>
      <div className="col-md-8">
        <Form.Control type="text" id="payableamount" name="payableamount" placeholder="0" value={salaryData.payableamount} onChange={handleChange} />
      </div>
    </div>
  </Form.Group>
</div>

                             
                              <Form.Group className="form-group text-end">
                                    <Button type="submit" variant="btn btn-primary">Update</Button>{' '}
                                     <Link to={'/staffmanagement'}><Button type="button" variant="btn btn-danger">cancel</Button></Link>
                                </Form.Group>
                             
                            </Form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
            </>
       ):(
       <><Navigate to="/staffmanagement"/></>
       )}
            </div>
  );
}

export default StaffEditSalary;
