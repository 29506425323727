import React,{useState,useEffect} from 'react'
import { Form, Nav , Tab, Row, Col, InputGroup,Button,Image } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link,useParams } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import avatars1 from '../../assets/images/avatars/01.png'
import avatars2 from '../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../assets/images/avatars/avtar_5.png'
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
const TranscertEdit = () => {
    const {id}=useParams()
    const[isLoading, setIsLoading]=useState(true);
    const [formData, setFormData] = useState({});
    const [postfromData, setPostFromData] = useState({});
   const[imagePreview,setImagePreview]=useState('');
 const studentData=useSelector(selectStudentStatus);

   const[duration,setDuration] = useState('');
  
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const navigate = useNavigate();

const handleDateChange = (selectedDates, dateField) => {
    // const selectedDate = selectedDates[0];
    // setFormData({
    //   ...formData,
    //   [dateField]: selectedDate,
    // });
    const selectedDate = selectedDates[0];
    let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
    formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
    setPostFromData({
        ...postfromData,
        [dateField]: formattedDate, // Set the formatted date in the formData
    });
  };

   const handlePostChange = (e) => {
    const { name, value } = e.target;
    setPostFromData({
      ...postfromData,
      [name]: value,
    });
  };

  // Function to calculate total amount


useEffect(() => {
    // Fetch billing information only once when component mounts
    fetchTranscert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);



const fetchTranscert = async () => {
    try {
        const res = await axios.get('/api/transcert/' + id);
       
        console.log(res.data.studentdata);
        setFormData(res.data.studentdata[0]);
         setPostFromData(res.data.tcdata);
         console.log(formData)
 setIsLoading(false);

 setIsButtonEnabled(true)
        if (res.data.studentdata && res.data.studentdata[0].profilePic) {
            // Set the imagePreview state with the absolute URL of the profile picture
            const imagePath = process.env.REACT_APP_API_BASE_URL+'/'+res.data.studentdata[0].profilePic;
            setImagePreview(imagePath);
        }
        // // Pre-select state and city based on the fetched data
        // handleStateChange({ target: { value: res.data.student.state } });
        // handleyearChange({ target: { value: res.data.student.academic_year } });
    } catch (err) {
        console.log(err.response);
    }
};

  const generateYearOptions = () => {
   let temp;
   if(duration===''){
       temp=4;
   }
   else{
       temp=duration;
   }
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(temp);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};

const handleSubmit = (e) => {
    e.preventDefault();
      const formDataToSend = new FormData();

    // Append all form data fields to formDataToSend
    Object.entries(postfromData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
    });

    // Append the profilePic file to formDataToSend
    formDataToSend.append('_method','PUT');
    console.log(formDataToSend)
SendData(formDataToSend)
    // Handle form submission with formData
  };

  const SendData = async(formDataToSend) =>{
     const loaderid =  toast.loading("Updating data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.put(`/api/transcertupdate/${id}`, postfromData);

        // If request is successful
        console.log(res.data);
        toast.update(loaderid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/transfercertificate');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
               

          
                <Row>
               <Col xl="3" lg="4" className="">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Student Details</h4>
                        </div>
                     </Card.Header>
                     <Card.Body className="text-start">
                        <Form>
                           <Form.Group className="form-group">
                                <div className="profile-img-edit position-relative">
                                    {imagePreview ? (
                                        <Image src={imagePreview} alt="profile-preview" className="profile-pic rounded avatar-100" />
                                    ) : (
                                        <>
                                            <Image className="theme-color-default-img profile-pic rounded avatar-100" src={avatars1} alt="profile-pic"/>
                                            <Image className="theme-color-purple-img profile-pic rounded avatar-100" src={avatars2} alt="profile-pic"/>
                                            <Image className="theme-color-blue-img profile-pic rounded avatar-100" src={avatars3} alt="profile-pic"/>
                                            <Image className="theme-color-green-img profile-pic rounded avatar-100" src={avatars5} alt="profile-pic"/>
                                            <Image className="theme-color-yellow-img profile-pic rounded avatar-100" src={avatars6} alt="profile-pic"/>
                                            <Image className="theme-color-pink-img profile-pic rounded avatar-100" src={avatars4} alt="profile-pic"/>
                                        </>
                                    )}
                                    
                                </div>

                              {/* <div className="img-extension mt-3">
                                 <div className="d-inline-block align-items-center">
                                    <span>Only</span>{' '}
                                    <Link to="#">.jpg</Link>{' '}
                                    <Link to="#">.png</Link>{' '}
                                    <Link to="#">.jpeg</Link>{' '}
                                    <span>allowed</span>
                                 </div>
                              </div> */}
                           </Form.Group>
                          
                           <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="pno">Student ID Number:</Form.Label>
                                    <Form.Control class="form-control form-control-sm" type="text"  id="student_id_number" name="student_id_number" placeholder="" value={formData.student_id_number} disabled/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="city">Aadhar Number:</Form.Label>
                                    <Form.Control class="form-control form-control-sm" type="text"  id="government_id" name="government_id" placeholder="" value={formData.government_id} disabled/>
                                 </Form.Group>
                                 
                                
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="current_program">Current Course:</Form.Label>
                                    {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                     <select
                                        name="current_program"
                                        id="current_program"
                                        className="selectpicker form-control form-control-sm"
                                       value={formData.current_program}
                                       disabled
                                    >
                                        <option>Select Course</option>
                                        {studentData.coursenames.data.results.map((course, index) => (
                                        <option key={index} value={course.id}>
                                            {course.coursename}
                                        </option>
                                        ))}
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
      <Form.Label>Academic Year:</Form.Label>
      <select
        name="academic_year"
        id="academic_year"
        className="selectpicker form-control form-control-sm"
        data-style="py-0"
        value={formData.academic_year}
       disabled
      >
        <option value="">Select</option>
        {generateYearOptions()}
      </select>
    </Form.Group>
                                
                                  <Form.Group className="col-md-12 form-group">
                                    <Form.Label>Current Year:</Form.Label>
                                    <select name="current_year" id="current_year" className="selectpicker form-control form-control-sm" data-style="py-0"  value={formData.current_year} disabled>
                                       <option>Select</option>
                                       <option>I</option>
                                       <option>II</option>
                                       <option >III</option>
                                       <option >IV</option>
                                    </select>
                                 </Form.Group>
                                 
                                                           
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
            <Col xl="9" lg="8">
    <Card>
        <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
                <h4 className="card-title">TC Information</h4>
            </div>
        </Card.Header>
        <Card.Body className="text-start">
            <div className="new-user-info">
                <Form className="form-horizontal" onSubmit={(e) => handleSubmit(e)}>
                    
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">TC Number:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_number" name="tc_number" placeholder="" value={postfromData.tc_number} onChange={handlePostChange} />
                            <input type="hidden" id="tc_studid" name="tc_studid" placeholder="" value={postfromData.tc_studid} onChange={handlePostChange}></input>
                           
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Name of the Student:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_studname" name="tc_studname" placeholder="" value={postfromData.tc_studname} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Name of the Parent / Guardian:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_guardian" name="tc_guardian" placeholder="" value={postfromData.tc_guardian} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Nationality & Religion:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_natrel" name="tc_natrel" placeholder="" value={postfromData.tc_natrel} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Community & Caste:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_caste" name="tc_caste" placeholder="" value={postfromData.tc_caste} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Date of Birth as entered in the Admission Register:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_dob" name="tc_dob" placeholder="" value={postfromData.tc_dob} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Gender:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_gender" name="tc_gender" placeholder="" value={postfromData.tc_gender} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Date of Admission:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_doa" name="tc_doa" placeholder="" value={postfromData.tc_doa} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Course and Year to which the student was admitted : </Form.Label>
                        <div className="col-md-6 mb-4">
                            <select
                                name="tc_adcourse"
                                id="tc_adcourse"
                                className="selectpicker form-control form-control-sm"
                                value={postfromData.tc_adcourse}
                                disabled
                            >
                                <option>Select Course</option>
                                {studentData.coursenames.data.results.map((course, index) => (
                                <option key={index} value={course.id}>
                                    {course.coursename}
                                </option>
                                ))}
                            </select>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Course in which the student was studying at the time of leaving : </Form.Label>
                        <div className="col-md-6 mb-4">
                             <select
                                name="tc_course"
                                id="tc_course"
                                className="selectpicker form-control form-control-sm"
                                value={postfromData.tc_course}
                                disabled
                            >
                                <option>Select Course</option>
                                {studentData.coursenames.data.results.map((course, index) => (
                                <option key={index} value={course.id}>
                                    {course.coursename}
                                </option>
                                ))}
                            </select>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Whether qualified for promotion to Higher Class :</Form.Label>
                        <div className="col-md-6 mb-4">
                            <input className="form-control form-control-sm" type="text" id="tc_qualified" name="tc_qualified" placeholder="" value={postfromData.tc_qualified} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Reason for leaving the Institution :</Form.Label>
                        <div className="col-md-6 mb-4">
                            <input className="form-control form-control-sm" type="text" id="tc_reason" name="tc_reason" placeholder="" value={postfromData.tc_reason} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Date on which the student actually left the Institution :</Form.Label>
                        <div className="col-md-6 mb-4">
                           
                                    <Flatpickr
                                       id="tc_releivingdate"
                                       name="tc_releivingdate"
                                       value={postfromData.tc_releivingdate}
                                       onChange={(selectedDates) =>
                                          handleDateChange(selectedDates, 'tc_releivingdate')
                                       }
                                       options={{
                                          dateFormat: 'd-m-Y', // Customize date format
                                          altInput: true, // Enable alternate input
                                          altFormat: 'd-m-Y', // Alternate input format
                                           maxDate: new Date(), // Show previous 1 week + future dates
                                       }}
                                       className="form-control" // Add any additional CSS classes here
                                    />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Date of issue of Transfer Certificate :</Form.Label>
                        <div className="col-md-6 mb-4">
                            <Flatpickr
                                       id="tc_date"
                                       name="tc_date"
                                       value={postfromData.tc_date}
                                       onChange={(selectedDates) =>
                                          handleDateChange(selectedDates, 'tc_date')
                                       }
                                       options={{
                                          dateFormat: 'd-m-Y', // Customize date format
                                          altInput: true, // Enable alternate input
                                          altFormat: 'd-m-Y', // Alternate input format
                                           maxDate: new Date(), // Show previous 1 week + future dates
                                       }}
                                       className="form-control" // Add any additional CSS classes here
                                    />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Student's Conduct and Character :</Form.Label>
                        <div className="col-md-6 mb-4">
                            <input className="form-control form-control-sm" type="text" id="tc_conduct" name="tc_conduct" placeholder="" value={postfromData.tc_conduct} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <div className="text-center">
                        <Button type="submit" id="savebutton" variant="btn btn-primary" disabled={!isButtonEnabled}>Save</Button>
                    </div>
                </Form>
            </div>
        </Card.Body>
    </Card>
</Col>


            </Row>
            </div>
  );
}

export default TranscertEdit;
