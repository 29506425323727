import { createSlice } from "@reduxjs/toolkit";


  const initialState = {
    loadingstatus:false,
    error:null,
  };

  const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        logout: (state) => {
            return initialState; // Reset state to initial state upon logout
          }
    }

  });

  export const selectStaffStatus=(state)=>state.staff;
 export const { logout } = staffSlice.actions;

export default staffSlice.reducer; // Ensure to export the loginSlice reducer

