import { createSlice } from "@reduxjs/toolkit";





const initialState = {
  feesdetails:{}
 
};

const hostelfeeSlice = createSlice({
  name: "hostelfees",
  initialState,
  reducers: {
   setfeesdetails: (state, action) => {
      state.feesdetails = action.payload
    },
   logout: (state) => {
      return initialState; // Reset state to initial state upon logout
    }

   
  },

});

export const { logout,setfeesdetails } = hostelfeeSlice.actions;
export const selectFeesStatus=(state)=>state.hostelfees;
export default hostelfeeSlice.reducer; // Ensure to export the loginSlice reducer