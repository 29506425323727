import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import Busbilling from '../../components/Snippets/Busbilling';
import axios from '../../api/axios';
import { useParams } from 'react-router-dom';

const BillingBusPrint = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [postfromData, setPostFromData] = useState({});
  const[coursename, setCoursename] = useState("");

  useEffect(() => {
    fetchBilling();
  }, []);

  const fetchBilling = async () => {
    try {
      const res = await axios.get('/api/invoicebus/' + id);
      setIsLoading(false);
      console.log(res.data);
      setPostFromData(res.data.invoice);
      setCoursename(res.data.course_name)
    } catch (err) {
      console.log(err.response);
    }
  };

 

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <Busbilling postfromData={postfromData} coursename={coursename} />
        </PDFViewer>
      )}
    </>
  );
};

export default BillingBusPrint;
