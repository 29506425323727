import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
 
};

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
   setProgressPercent: (state, action) => {
      const { name, progresspercent } = action.payload;
      const submodule = state.submodules.find(submodule => submodule.name === name);
      if (submodule) {
        submodule.progresspercent = progresspercent;
      }
    },
   logout: (state) => {
      return initialState; // Reset state to initial state upon logout
    },
    setcurrentsubmodulename: (state, action) => {
      const { linktarget } = action.payload;
     // console.log(action.payload)
      const matchedSubmodule = state.submodules.find(submodule => submodule.linktarget === action.payload);
      if (matchedSubmodule) {
        state.currentsubmodulename = matchedSubmodule.name;
      }
    },
    resetcurrentsubmodulename:(state) => {
      state.currentsubmodulename=null
    }

   
  },

});

export const { setProgressPercent,logout,setcurrentsubmodulename,resetcurrentsubmodulename } = courseSlice.actions;
export const selectCourseStatus=(state)=>state.course;
export default courseSlice.reducer; // Ensure to export the loginSlice reducer
