import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../../components/defaultSnippets/Card';
import { useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function AttendanceReportLandingMain() {
    
    const [courses, setCourses] = useState('');
    const [searchData, setSearchData] = useState({
        att_courseid: '', att_date: '', att_role: '', att_presence: '', att_type: ''
    });

    const fetchCourses = async () => {
        const findid = toast.loading("Fetching Courses data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.get('/api/attendancereportmain');
            setCourses(res.data.results);
            toast.dismiss(findid); // Dismiss the loading toast when data is fetched successfully
        }
        catch (err) {
            console.log(err.response);
            toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    const handleDateChange = (selectedDates, dateField) => {
        const selectedDate = selectedDates[0];
        let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
        formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [dateField]: formattedDate,
        }));
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [name]: value,
        }));
    };

    

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchData.att_courseid !== "") {
            if (searchData.att_role === "student" || searchData.att_role === "staff") {
                fetchReport();
            } else {
                toast.error("Please select a role!", {
                    position: "bottom-right",
                    autoClose: 3000,
                    theme: "dark",
                });
            }
        } else {
            // Handle missing fields
            toast.error("Please complete all required fields!", {
                position: "bottom-right",
                autoClose: 3000,
                theme: "dark",
            });
        }
    };

    const fetchReport = async () => {
        const findid = toast.loading("Fetching report data...", {
            position: "bottom-right",
            autoClose: 3000,
            theme: "dark",
        });

        try {
            let res='';
            if(searchData.fetchtype=="session")
            {
                res = await axios.get('/api/attendancereportsessionbased', {
                    params: searchData // Pass searchData as query parameters
                });
            }
            else if(searchData.fetchtype=="day")
            {
                res = await axios.get('/api/attendancereportdaybased', {
                    params: searchData // Pass searchData as query parameters
                });
            }
            else
            {
                toast.error("Please select report type!", {
                    position: "bottom-right",
                    autoClose: 3000,
                    theme: "dark",
                });
                
                toast.dismiss(findid);

                return;
            }
           

            const response = await fetch(`${process.env.PUBLIC_URL}/attendanceoverall.xlsx`);

            if (!response.ok) {
                throw new Error("Failed to fetch the Excel template.");
            }

            const workbook = new ExcelJS.Workbook();

            const blob = await response.blob();

            // Load the workbook from the blob
            await workbook.xlsx.load(blob);
            
            const worksheet = workbook.getWorksheet(1); // Get the first worksheet
            console.log(res.data.result);
           const dataArray = Object.values(res.data.result);
        //const dataArray = res.data.result;
             console.log(dataArray);
const datecells=['C6','K6','S6','AA6','AI6','AQ6','AY6','BG6','BO6','BW6','CE6','CM6','CU6','DC6','DK6','DS6','EA6','EI6','EQ6','EY6','FG6','FO6','FW6','GE6','GM6','GU6','HC6','HK6','HS6','IA6','II6'];
const startCells=[2,10,18,26,34,42,50,58,66,74,82,90,98,106,114,122,130,138,146,154,162,170,178,186,194,202,210,218,226,234,242];
// Function to convert column index to Excel column letter
function columnIndexToLetter(columnIndex) {
  let temp; // Temporary variable to hold the letter result
  let letter = ''; // Initialize the letter result

  while (columnIndex > 0) {
    // Get the modulo and adjust the index
    temp = (columnIndex) % 26;
    letter = String.fromCharCode(temp + 65) + letter; // Prepend the character
    columnIndex = Math.floor((columnIndex - temp) / 26); // Move to the next letter
  }

  return letter; // Return the final letter string
}
// if(searchData.att_type==='monthly' && searchData.att_role==="student")
// {
    Object.keys(dataArray).forEach((mainKey, mainIndex) => {
        console.log(`Main Key: ${mainIndex}`);
        const RowIndex = 7 + mainIndex;

        // Set the main key index
        worksheet.getCell(`A${RowIndex}`).value = mainIndex + 1 || "test"; 

        // Loop through each nested key (03, 04, 11, etc.)
        Object.keys(dataArray[mainKey]).forEach((nestedKey, nestedIndex) => {
            console.log(`  Nested Key: ${nestedKey}`);

            let valueSet = false;
            const startColumnIndex = startCells[nestedIndex]; // Get the starting column index

            // Loop through the array of values inside each nested key
            dataArray[mainKey][nestedKey].forEach((entry, entryIndex) => {
            if (!valueSet) {  // Only set the value if it hasn't been set for this mainKey
                const dateCell = datecells[nestedIndex]; // Get the corresponding date cell based on mainIndex
                console.log(dateCell);
                if (dateCell) {  // Ensure there's a corresponding datecell
                worksheet.getCell(dateCell).value = entry.date || "test"; 
                worksheet.getCell(`B${RowIndex}`).value = entry.studentname || "test"; 
                valueSet = true; // Mark that we've set the value
                }
            }

            // Calculate the current column letter for the status based on the entry index
            const currentColumnIndex = startColumnIndex + entryIndex; // Adjust column index
            const currentColumnLetter = columnIndexToLetter(currentColumnIndex); // Convert to Excel column letter

            console.log(`Current Column: ${currentColumnLetter}${RowIndex}`);

            // Set the status value
            if (entry.status === "present") {
                worksheet.getCell(`${currentColumnLetter}${RowIndex}`).value = "x"; // Set to "x" if status is present
            } else {
                worksheet.getCell(`${currentColumnLetter}${RowIndex}`).value = "A"; // Set to "A" otherwise
                // Set the font color to red for "A"
                worksheet.getCell(`${currentColumnLetter}${RowIndex}`).font = { color: { argb: 'FF0000' } }; // Red color
            }

            console.log(`    Entry:`, entry);
            console.log(`    Entry Student Name:`, entry.studentname);
            });
        });
    });
// }
// Loop through each main key (9, 12, etc.)




            // dataArray.forEach((item, index) => {

            //     item[index].forEach((itemd, indexd) => {
            //         console.log(itemd);
            //     })
            //     const targetRow = index + 6; 
            //      worksheet.getCell('C6').value = item[index].date;
            //      console.log(item[index].date +"->"+item[index].session);
            //      if(item[index].session==="session1")
            //      {
            //         alert("coming")
            //         if(item[index].attlog_status==="present")
            //         {
            //             alert(true);
            //             worksheet.getCell('C7').value = 'x';
            //         }
            //         else
            //         {
            //             alert(false);
            //             worksheet.getCell('C7').value = 'A';
            //         }
                    
            //      }

               
            // })

             const buffer = await workbook.xlsx.writeBuffer();
        const fileBlob = new Blob([buffer], { type: 'application/octet-stream' });

        // Trigger the download
        saveAs(fileBlob, 'exported_attendance.xlsx');

        alert("Export successful!");


            toast.dismiss(findid);
        } catch (err) {
            console.log(err.response);
            toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    return (
        <>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Generate Attendance Report</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="align-items-center">
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="att_role"
                                                id="att_role"
                                                className="form-control"
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Role</option>
                                                <option value='staff'>Staff</option>
                                                <option value='student'>Student</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="att_type"
                                                id="att_type"
                                                className="form-control"
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Type</option>
                                                {searchData.att_role === 'student' ? (
                                                    <>
                                                        <option value='daily'>Daily</option>
                                                        <option value='monthly'>Monthly</option>
                                                        <option value='I'>Semester 1</option>
                                                        <option value='II'>Semester 2</option>
                                                        <option value='III'>Semester 3</option>
                                                        <option value='IV'>Semester 4</option>
                                                        <option value='V'>Semester 5</option>
                                                        <option value='VI'>Semester 6</option>
                                                        <option value='VII'>Semester 7</option>
                                                        <option value='VIII'>Semester 8</option>
                                                    </>
                                                ) : (
                                                    <>
                                                        <option value='daily'>Daily</option>
                                                        <option value='monthly'>Monthly</option>
                                                    </>
                                                )}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="3">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="att_courseid"
                                                id="att_courseid"
                                                className="form-control"
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Course</option>
                                                {Object.entries(courses).map(([key, course], index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>

                                    {/* Conditionally render att_date or att_month based on att_type */}
                                    {searchData.att_type === 'daily' && (
                                        <Col sm="2">
                                            <Form.Group className="mb-3 mb-sm-0">
                                                <Flatpickr
                                                    id="att_date"
                                                    name="att_date"
                                                    onChange={(selectedDates) =>
                                                        handleDateChange(selectedDates, 'att_date')
                                                    }
                                                    options={{
                                                        dateFormat: 'd-m-Y',
                                                        altInput: true,
                                                        altFormat: 'd-m-Y',
                                                        maxDate: new Date(),
                                                    }}
                                                    className="form-control"
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}

                                    {searchData.att_type === 'daily' || searchData.att_type === 'monthly' && (
                                        <Col sm="2">
                                            <Form.Group className="mb-3 mb-sm-0">
                                                <Flatpickr
                                                    id="att_month"
                                                    name="att_month"
                                                    onChange={(selectedDates) =>
                                                        handleDateChange(selectedDates, 'att_month')
                                                    }
                                                    options={{
                                                        dateFormat: 'M Y',
                                                        altInput: true,
                                                        altFormat: 'M Y',
                                                        plugins: [new monthSelectPlugin({
                                                            shorthand: true,
                                                            dateFormat: "m.y",
                                                            altFormat: "M Y",
                                                        })],
                                                        maxDate: new Date(),
                                                    }}
                                                    className="form-control"
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}

<Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="fetchtype"
                                                id="fetchtype"
                                                className="form-control"
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Report Type</option>
                                                <option value='session'>Session</option>
                                                <option value='day'>Day</option>
                                            </select>
                                        </Form.Group>
                                    </Col>

                                    <Col sm="1">
                                        <div className="text-end">
                                            <Button type="button" id="searchbutton" variant="primary" onClick={handleSearch}>Get</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default AttendanceReportLandingMain;
