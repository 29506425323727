import React,{useState,useEffect} from 'react'
import { Form, Nav , Tab, Table,Row, Col, InputGroup,Button } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import { FaEdit,FaTrashAlt  } from 'react-icons/fa';
const RoleEdit = () => {
    const {id}=useParams()
//alert(id);
const[isLoading, setIsLoading]=useState(true);
const[permissions, setPermissions]=useState({})
const [role, setRole]=useState([])
const [formData, setFormData] = useState({
    name: '',
    // Add more fields as needed
  });
  const [checkedPermissions, setCheckedPermissions] = useState({});

    const fetchCourse=async()=>{
         const fetchingid =  toast.loading("Getting role...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
        try{
            const res = await axios.get('/api/role/'+id);
            console.log(res.data.role)
            setIsLoading(false)
            setFormData(res.data.role);
            setPermissions(res.data.permissiondata);
            setRole(res.data.rolevals.permissions)
            console.log(res.data.permissiondata);
            toast.update(fetchingid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        }catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(fetchingid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(fetchingid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
    }

    useEffect(()=>{
        fetchCourse();
    },[id]);
 

  const navigate = useNavigate();

   const handleChange = (e) => {
    //alert("sdfsdf")
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    //  console.log("Checkbox Name:", name);
    // console.log("Checked:", checked);
 // Update checkedPermissions state based on checkbox change
     
  };

  const handleCheckboxChange=(e)=>{
    const { name, checked } = e.target;
    const updatedRole = checked
        ? [...role, name]
        : role.filter((permission) => permission !== name);
    setRole(updatedRole);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const checkedPermissionValues = Object.keys(checkedPermissions).filter((key) => checkedPermissions[key]);
       // console.log('Checked permissions:', checkedPermissionValues);
      //  return false;
    console.log(formData);
      const formDataToSend = new FormData();

    // Append all form data fields to formDataToSend
    Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
    });

    // Append the profilePic file to formDataToSend
    formDataToSend.append('_method','PUT');
role.forEach((key) => {
        formDataToSend.append(key, true); // Or you can specify a value if needed
    });
     console.log(formDataToSend);
SendData(formDataToSend)
    // Handle form submission with formData
  };

  const SendData = async(formDataToSend) =>{
     const loaderid =  toast.loading("Updating role...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post(`/api/roleupdate/${id}`, formDataToSend);

        // If request is successful
        console.log(res.data);
        toast.update(loaderid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/rolemanagement');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
                <Row>
                   
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Edit Role</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                              {isLoading ? (
                                <p>Loading Data....</p>
                              ):(
                                <Form className="form-horizontal" onSubmit={(e)=>handleSubmit(e)}>
                                <Form.Group as={Row} className="form-group">
                                    <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="email">Role Name:</Form.Label>
                                    <Col sm="9">
                                    <Form.Control name="name"  placeholder="Enter Role Name" value={formData.name} onChange={handleChange} required/>
                                    </Col>
                                </Form.Group>
                                <div className="header-title text-start mt-4">
                                    <h4 className="card-title">Permissions</h4>
                                </div>
                              <Table responsive id="datatable" className="text-start" size="md" data-toggle="data-table">
                                <thead className='text-start'>
                                    <tr>
                                        <th style={{}}>Permission</th>
                                        <th style={{}}>Create</th>
                                        <th style={{}}>View</th>
                                        <th style={{}}>Edit</th>
                                        <th style={{}}>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {isLoading ? (
    <tr>
        <td colSpan="6">Loading Data....</td>
    </tr>
) : (
    permissions.reduce((acc, item, index) => {
    const splitName = item.name.split('-');
    // Check if the splitName array has at least two elements
    if (splitName.length >= 2) {
        // Match the second part of the split value
        const secondPart = splitName[1];
        // If the second part has not been seen before, add it to the set
        if (!acc.seenSecondParts.has(secondPart)) {
            acc.seenSecondParts.add(secondPart);
            const uniqueKey = `row_${secondPart}`;
            acc.rows.push(
                <tr key={uniqueKey}>
                    <td style={{ padding: '8px' }}>{splitName[1].charAt(0).toUpperCase() + splitName[1].slice(1)}</td>
                    <td><input type="checkbox" name={`create-${splitName[1]}`} checked={role.includes(`create-${splitName[1]}`)} onChange={handleCheckboxChange} /></td>
                    <td><input type="checkbox" name={`view-${splitName[1]}`} checked={role.includes(`view-${splitName[1]}`)} onChange={handleCheckboxChange} /></td>
                    <td><input type="checkbox" name={`edit-${splitName[1]}`} checked={role.includes(`edit-${splitName[1]}`)} onChange={handleCheckboxChange} /></td>
                    <td><input type="checkbox" name={`delete-${splitName[1]}`} checked={role.includes(`delete-${splitName[1]}`)} onChange={handleCheckboxChange} /></td>
                </tr>
            );
        }
    }
    return acc;
}, { seenSecondParts: new Set(), rows: [] }).rows
)}
                                </tbody>
                            </Table>



                                <Form.Group className="form-group text-end">
                                    <Button type="submit" variant="btn btn-primary">Submit</Button>{' '}
                                    <Link to={'/rolemanagement'}><Button type="button" variant="btn btn-danger">cancel</Button></Link>
                                </Form.Group>
                            </Form>
                              )}
                                
                            </Card.Body>
                            
                        </Card>
                        
                       
                    </Col>
                    
                </Row>
            </div>
  );
}

export default RoleEdit;