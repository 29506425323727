import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../components/defaultSnippets/Card';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';

const BusFeesEdit = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({});
    const [selectedYear, setSelectedYear] = useState('');
    const [busstopList, setBusstopList] = useState([]);
 const navigate = useNavigate();
    const getBusstops = async () => {
        const bsid = toast.loading("Getting Bus stops...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.get('/api/busstoplist');
            if (res.data && res.data.results) {
                setBusstopList(res.data.results);
                setIsLoading(false);
                toast.update(bsid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
            } else {
                toast.update(bsid, { render: "No bus stops found", type: "warning", isLoading: false, autoClose: 3000, theme: "colored" });
            }
        } catch (err) {
            toast.update(bsid, { render: err.response?.data?.message || "An error occurred", type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    useEffect(() => {
        getBusstops();
    }, []);

    const fetchFeesMaster = async () => {
        try {
            const res = await axios.get(`/api/busfees/${id}`);
            const stopData = res.data.stop;
            setSelectedYear(stopData.busfeeacyear);
        //     setFormData(prevFormData => ({
        //     ...prevFormData,
        //     busfeeacyear: stopData.busfeeacyear
        // }));
            const feesMap = stopData.busfeesdetails.reduce((acc, detail) => {
                acc[detail.busstopid] = detail;
                return acc;
            }, {});

            console.log(feesMap);
            // return false;
             setFormData(prevFormData => ({
            ...feesMap,...prevFormData,
            busfeeacyear: stopData.busfeeacyear // Ensure busfeeacyear is included
        }));
            setIsLoading(false);
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred");
        }
    };

    useEffect(() => {
        fetchFeesMaster();
    }, [id]);

   const handleyearChange = (e) => {
        const newYear = e.target.value;
        setSelectedYear(newYear);

        setFormData(prevFormData => ({
            ...prevFormData,
            busfeeacyear: newYear
        }));
    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i <= currentYear - 2020; i++) {
            const startYear = currentYear - i;
            const endYear = startYear + 1; // Use 1 to show the next year
            years.push(`${startYear}-${endYear}`);
        }
        return years.map(year => (
            <option key={year} value={year}>
                {year}
            </option>
        ));
    };

    const handleChange = (e, id) => {
        const { value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: { ...prevFormData[id], busfeeamount: value } // Update specific fee amount
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        Object.entries(formData).forEach(([key, detail]) => {
            formDataToSend.append(key, detail.busfeeamount);
        });
         formDataToSend.append('_method', 'PUT');
        formDataToSend.delete('busfeeacyear');
        formDataToSend.append('busfeeacyear',selectedYear);
        //console.log(formDataToSend)
       // return false;
        SendData(formDataToSend);
    };

    const SendData = async (formDataToSend) => {
        const loaderid = toast.loading("Updating data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.post(`/api/busfeesupdate/${id}`, formDataToSend);
            toast.update(loaderid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
            navigate('/busfeesmanagement');
        } catch (err) {
            toast.update(loaderid, { render: err.response?.data?.message || "An error occurred", type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    return (
        <div>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Edit Bus Fees</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {isLoading ? (
                                <p>Loading Data....</p>
                            ) : (
                                <Form className="form-horizontal" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <Form.Group className="col-md-6 form-group">
                                            <Form.Label htmlFor="academic_year" style={{ display: 'flex' }}>Academic Year:</Form.Label>
                                            <select
                                                name="busfeeacyear"
                                                id="busfeeacyear"
                                                className="selectpicker form-control"
                                                data-style="py-0"
                                                value={selectedYear}
                                                onChange={handleyearChange}
                                            >
                                                <option value="">Select</option>
                                                {generateYearOptions()}
                                            </select>
                                        </Form.Group>
                                    </div>
                                    {busstopList.length > 0 && busstopList.map((stop) => {
                                        const matchingFeeDetail = formData[stop.id] || { busfeeamount: '' };
                                        return (
                                            <Form.Group as={Row} key={stop.id} className="form-group">
                                                <Form.Label column sm="2" className="control-label mb-0 text-start">
                                                    {stop.busstopname}
                                                </Form.Label>
                                                <Col sm="9">
                                                    <Form.Control
                                                        type="text"
                                                        id={`busstop_${stop.id}`}
                                                        name={`busstop_${stop.id}`}
                                                        placeholder=""
                                                        value={matchingFeeDetail.busfeeamount}
                                                        onChange={(e) => handleChange(e, stop.id)}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        );
                                    })}
                                    <Form.Group className="form-group text-end">
                                        <Button type="submit" variant="btn btn-primary">Submit</Button>{' '}
                                        <Link to={'/busfeesmanagement'}><Button type="button" variant="btn btn-danger">Cancel</Button></Link>
                                    </Form.Group>
                                </Form>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default BusFeesEdit;
