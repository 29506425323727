
import React, { useState } from 'react'

import SubHeaderL from '../layouts/SubHeaderL';
import SwiperE from '../../components/Snippets/SwiperE';
import { persistor } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getStateNames,getCityNames,getCourseNames,getRoleNames } from '../Students/studentSlice';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectStudentStatus } from '../Students/studentSlice';
import { setcurrentmodulename } from '../login/loginSlice';
import { FaUserGraduate, FaUserAlt, FaBook, FaAddressCard, FaRupeeSign } from 'react-icons/fa';
import axiosapi from '../../api/axios';

const Dashboard = ({ valueFromRoute,valueFromSubroute }) => {
  const dispatch=useDispatch()
  const statenames=useSelector(selectStudentStatus);
  const [studentcount, setstudentcount]=useState(0);
  const [staffscount, setstaffcount]=useState(0);
  const[subjectscount, setsubjectscount]=useState(0);
  const [coursescount, setcoursescount]=useState(0);
 
  useEffect(()=>{
 
 if (statenames && statenames.statenames && statenames.statenames.data && statenames.statenames.data.results) {
  console.log("Existing data");
  console.log(statenames.statenames.data.results);
} else {
  // Fetch data only if statenames.statenames.data.results doesn't exist or statenames is null
  console.log("Data not available, fetching...");
  fetchStatenames();
}

if (statenames && statenames.citynames && statenames.citynames.data && statenames.citynames.data.results) {
  console.log("Existing data cities");
  console.log(statenames.citynames.data.results);
} else {
  // Fetch data only if statenames.statenames.data.results doesn't exist or statenames is null
  console.log("Data not available, fetching cities...");
  fetchCitynames();
}

if (statenames && statenames.coursenames && statenames.coursenames.data && statenames.coursenames.data.results) {
  console.log("Existing data courses");
  console.log(statenames.coursenames.data.results);
} else {
  // Fetch data only if statenames.statenames.data.results doesn't exist or statenames is null
  console.log("Data not available, fetching courses...");
  fetchCoursenames();
}

if (statenames && statenames.rolenames && statenames.rolenames.data && statenames.rolenames.data.results) {
  console.log("Existing data courses");
  console.log(statenames.coursenames.data.results);
} else {
  // Fetch data only if statenames.statenames.data.results doesn't exist or statenames is null
  console.log("Data not available, fetching courses...");
  fetchRolenames();
}
   
    fetchDashboardDetials()
  },[])

  const fetchStatenames=()=>{
    const statenameid =  toast.loading("Fetching States...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
    dispatch(getStateNames()).then(result=>
      {
         //console.log(result);
         if(result.payload.status===200){
          console.log(result.payload.data);
            toast.update(statenameid, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
            //setEmail('')
            //setPassword('')
         //console.log("ready to navigate")
          //navigate('/dashboard')
         }
         else
         {
            if(result.payload.status === 401)
            {
              console.log(result.payload.data);
            toast.update(statenameid, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
            }
         }
      })
  }

  const fetchCitynames=()=>{
    const citynameid =  toast.loading("Fetching Cities...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
    dispatch(getCityNames()).then(result=>
      {
         //console.log(result);
         if(result.payload.status===200){
          console.log(result.payload.data);
            toast.update(citynameid, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
            //setEmail('')
            //setPassword('')
         //console.log("ready to navigate")
          //navigate('/dashboard')
         }
         else
         {
            if(result.payload.status === 401)
            {
              console.log(result.payload.data);
            toast.update(citynameid, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
            }
         }
      })
  }

  const fetchCoursenames=()=>{
    const coursenameid =  toast.loading("Fetching Courses...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
    dispatch(getCourseNames()).then(result=>
      {
         //console.log(result);
         if(result.payload.status===200){
          console.log(result.payload.data);
            toast.update(coursenameid, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
            //setEmail('')
            //setPassword('')
         //console.log("ready to navigate")
          //navigate('/dashboard')
         }
         else
         {
            if(result.payload.status === 401)
            {
              console.log(result.payload.data);
            toast.update(coursenameid, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
            }
         }
      })
  }

  const fetchRolenames=()=>{
    const rolenameid =  toast.loading("Fetching Roles...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
    dispatch(getRoleNames()).then(result=>
      {
         //console.log(result);
         if(result.payload.status===200){
          console.log(result.payload.data);
            toast.update(rolenameid, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
            //setEmail('')
            //setPassword('')
         //console.log("ready to navigate")
          //navigate('/dashboard')
         }
         else
         {
            if(result.payload.status === 401)
            {
              console.log(result.payload.data);
            toast.update(rolenameid, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
            }
         }
      })
  }

  const fetchDashboardDetials = async() =>{
     const id =  toast.loading("Fetching dashboard details...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  await axiosapi.get('/api/dashboarddetails')
    .then(res=> {
     // console.log(res.data.results);
      // const jsonData = JSON.stringify(res.data.results);
    // dispatch(setfeesdetails(res.data.results));
      // localStorage.setItem('feeslist',jsonData);
      setstudentcount(res.data.studentcount);
      setstaffcount(res.data.staffscount);
      setsubjectscount(res.data.subjectscount);
      setcoursescount(res.data.coursescount);
      toast.update(id, { render: "Successfull", type: "success", isLoading: false, autoClose: 1000,theme: "light"}); 
     
  })
    .catch(err=>console.log(err))
  }

    return (
        <>
        <ToastContainer />
        
          <div classNameName="position-relative iq-navbar-header" style={{background:'#3A57E8', color:'#fff',padding:'25px'}}>
            <div style={{color:'#fff', textAlign:'left', fontFamily:'conthrax', fontSize:'25px', marginLeft:'10px'}}>Dashboard</div>
          </div>
          <div classNameName="container-fluid m-auto" style={{width:'98%'}}>
            <div className="row" style={{padding:'10px'}}>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="bg-success text-white rounded p-3">
                           <FaUserAlt style={{fontSize:'25px'}}/>
                        </div>
                        <div className="text-end">
                          Students
                              <h2 className="counter" style={{visibility:'visible'}}>{studentcount}</h2>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="bg-success text-white rounded p-3">
                         <FaUserGraduate style={{fontSize:'25px'}}/>
                        </div>
                        <div className="text-end">
                          Staff
                              <h2 className="counter" style={{visibility:'visible'}}>{staffscount}</h2>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="bg-success text-white rounded p-3">
                          <FaBook style={{fontSize:'25px'}}/>
                        </div>
                        <div className="text-end">
                          Subjects
                              <h2 className="counter" style={{visibility:'visible'}}>{subjectscount}</h2>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="bg-success text-white rounded p-3">
                          <FaAddressCard style={{fontSize:'25px'}}/>
                        </div>
                        <div className="text-end">
                          Courses
                              <h2 className="counter" style={{visibility:'visible'}}>{coursescount}</h2>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="bg-success text-white rounded p-3">
                         <FaRupeeSign style={{fontSize:'25px'}}/>
                        </div>
                        <div className="text-end">
                          Revenue
                              <h2 className="counter" style={{visibility:'visible'}}>75</h2>
                        </div>
                    </div>
                  </div>
              </div>
            </div> */}
      </div>
           
             {/* <button
        onClick={() => {
          persistor.purge();
         
        }}
      >
        purge state
      </button> */}
          </div>
        </>
    )
}

export default Dashboard
