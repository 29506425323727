import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../components/defaultSnippets/Card';
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function AttendanceReport() {
    const [students, setStudents] = useState([]);
    const [searchData, setSearchData] = useState({});
    const studentData = useSelector(selectStudentStatus);
    const [formData, setFormData] = useState({});
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [attendanceData, setAttendanceData] = useState({});
    const navigate = useNavigate();

    const semesterOptions = {
        I: ['I', 'II'],
        II: ['III', 'IV'],
        III: ['V', 'VI'],
        IV: ['VII', 'VIII']
        // Add more options based on your requirements
    };

    const fetchStudents = async () => {
        const findid = toast.loading("Fetching Students data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.get('/api/attendancestudentsearchall', {
                params: searchData // Pass searchData as query parameters
            });
            setStudents(res.data.results);
            toast.dismiss(findid); // Dismiss the loading toast when data is fetched successfully
        }
        catch (err) {
            // If request fails
            console.log(err.response);
            if (err.response && err.response.status === 422) {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            } else {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            }
        }
    }

    useEffect(() => {
        if (!searchData.att_courseid || !searchData.att_courseyear || !searchData.att_semester || !searchData.att_date) {
            setIsButtonEnabled(false);
        } else {
            setIsButtonEnabled(true);
        }
    }, [searchData]);

    const handleChange = (event, studentId, session) => {
        const { value } = event.target;
        // Update attendance data for the selected session and student
        setAttendanceData(prevAttendanceData => ({
            ...prevAttendanceData,
            [`${studentId}_session${session}`]: value
        }));

    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [name]: value,
        }));
    };

        const handleSearch=(e) => {
    e.preventDefault();
    console.log(searchData);
    fetchStudents()
}

    const handleDateChange = (selectedDates, dateField) => {
        const selectedDate = selectedDates[0];
        let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
        formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [dateField]: formattedDate,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle submission of attendance data
        console.log(attendanceData);
        const dataToSubmit = {
        ...attendanceData,
        ...searchData
    };

    // Handle submission of attendance data
    console.log(dataToSubmit);
    SendData(dataToSubmit);
     //   SendData()
    };

    const SendData = async(dataToSubmit) =>{
     const id =  toast.loading("Saving attendance data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post('/api/attendanceadd', dataToSubmit);

        // If request is successful
        console.log(res.data);
        toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/studentattendance');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }

    return (
        <>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Generate Attendance Report</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form >
                                <Row className="align-items-center">
                                    <Col sm="3">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="att_courseid"
                                                id="att_courseid"
                                                className="selectpicker form-control"
                                                value={searchData.att_courseid}
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Course</option>
                                                {studentData.coursenames.data.results.map((course, index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select name="att_courseyear" id="att_courseyear" className="selectpicker form-control" data-style="py-0" value={searchData.att_courseyear} onChange={handleSearchChange}>
                                                <option value=''>Select Year</option>
                                                <option>I</option>
                                                <option>II</option>
                                                <option>III</option>
                                                <option>IV</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select name="att_semester" id="att_semester" className="selectpicker form-control" data-style="py-0" value={searchData.att_semester} onChange={handleSearchChange}>
                                                <option value=''>Select Semester</option>
                                                {semesterOptions[searchData.att_courseyear] &&
                                                    semesterOptions[searchData.att_courseyear].map((semester, index) => (
                                                        <option key={index}>{semester}</option>
                                                    ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <Flatpickr
                                                id="att_date"
                                                name="att_date"
                                                value={formData.att_date}
                                                onChange={(selectedDates) =>
                                                    handleDateChange(selectedDates, 'att_date')
                                                }
                                                options={{
                                                    dateFormat: 'd-m-Y', // Customize date format
                                                    altInput: true, // Enable alternate input
                                                    altFormat: 'd-m-Y', // Alternate input format
                                                    maxDate: new Date(),
                                                }}
                                                className="form-control" // Add any additional CSS classes here
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="1">
                                        <div className="text-end">
                                            <Button type="button" id="searchbutton" variant="primary" onClick={handleSearch} disabled={!isButtonEnabled}>Proceed</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
        </>
    );
}

export default AttendanceReport;
