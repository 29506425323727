
import React from 'react'
import SubHeaderL from '../../layouts/SubHeaderL';
import SwiperE from '../../../components/Snippets/SwiperE';
import { Outlet } from 'react-router-dom';
const AttendanceReportLandingLayout = () => {
  
    return (
        <>
         <div className="position-relative" >
            <SubHeaderL />
          </div>
          <div className="container-fluid m-auto" style={{width:'98%'}}>
            {/* <SwiperE mainmodule="Hipaa"></SwiperE> */}
            <Outlet></Outlet>
            
          </div>
        
        </>
    )
}

export default AttendanceReportLandingLayout
