import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../../api/axios'

export const getStateNames = createAsyncThunk(
    "student/states",
    async (data,{rejectWithValue }) => {
      try 
      {
          const response = await axios.post("/api/getstates");
          if (response.status === 200) {
              //localStorage.setItem('stateslist', response.data);
              //localStorage.setItem('username', response.data.user.name);
             
              return { status: response.status, data: response.data };
          }
      } 
      catch (error) {
        if (error.response && error.response.status === 401) 
        {
          // Handle 401 unauthorized error
          // localStorage.setItem('token', '');
          // localStorage.setItem('username', '');
          return rejectWithValue({status: error.response.status, data: error.response.data.message});
        } else {
          // Handle other errors
          //localStorage.setItem('token', '');
          //localStorage.setItem('username', '');
          return rejectWithValue({status: error.response.status, data: error.response.data.message});
        }
      }
    }
  );

  export const getCityNames = createAsyncThunk(
    "student/cities",
    async (data,{rejectWithValue }) => {
      try 
      {
          const response = await axios.get("/api/getcities");
          if (response.status === 200) {
              //localStorage.setItem('stateslist', response.data);
              //localStorage.setItem('username', response.data.user.name);
             
              return { status: response.status, data: response.data };
          }
      } 
      catch (error) {
        if (error.response && error.response.status === 401) 
        {
          // Handle 401 unauthorized error
          // localStorage.setItem('token', '');
          // localStorage.setItem('username', '');
          return rejectWithValue({status: error.response.status, data: error.response.data.message});
        } else {
          // Handle other errors
          //localStorage.setItem('token', '');
          //localStorage.setItem('username', '');
          return rejectWithValue({status: error.response.status, data: error.response.data.message});
        }
      }
    }
  );

export const getCourseNames = createAsyncThunk(
    "student/courses",
    async (data,{rejectWithValue }) => {
      try 
      {
          const response = await axios.get("/api/courselist");
          if (response.status === 200) {
              //localStorage.setItem('stateslist', response.data);
              //localStorage.setItem('username', response.data.user.name);
             
              return { status: response.status, data: response.data };
          }
      } 
      catch (error) {
        if (error.response && error.response.status === 401) 
        {
          // Handle 401 unauthorized error
          // localStorage.setItem('token', '');
          // localStorage.setItem('username', '');
          return rejectWithValue({status: error.response.status, data: error.response.data.message});
        } else {
          // Handle other errors
          //localStorage.setItem('token', '');
          //localStorage.setItem('username', '');
          return rejectWithValue({status: error.response.status, data: error.response.data.message});
        }
      }
    }
  );

  export const getRoleNames = createAsyncThunk(
    "student/roles",
    async (data,{rejectWithValue }) => {
      try 
      {
          const response = await axios.get("/api/rolelist");
          if (response.status === 200) {
              //localStorage.setItem('stateslist', response.data);
              //localStorage.setItem('username', response.data.user.name);
             
              return { status: response.status, data: response.data };
          }
      } 
      catch (error) {
        if (error.response && error.response.status === 401) 
        {
          // Handle 401 unauthorized error
          // localStorage.setItem('token', '');
          // localStorage.setItem('username', '');
          return rejectWithValue({status: error.response.status, data: error.response.data.message});
        } else {
          // Handle other errors
          //localStorage.setItem('token', '');
          //localStorage.setItem('username', '');
          return rejectWithValue({status: error.response.status, data: error.response.data.message});
        }
      }
    }
  );

  const initialState = {
    loadingstatus:false,
    error:null,
    statenames:{},
    citynames:{},
    coursenames:{},
    rolenames:{},
  };

  const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        logout: (state) => {
            return initialState; // Reset state to initial state upon logout
          }
    },
  extraReducers: (builder)=>{
   builder.addCase(getStateNames.pending, (state) => {
  state.loadingstatus = true; // <-- Update here
  state.error = null;
});
builder.addCase(getStateNames.fulfilled, (state, action) => {
    state.loadingstatus = false; // <-- Update here
    state.statenames = action.payload;
    state.error = null;
  });
  builder.addCase(getStateNames.rejected, (state, action) => {
    state.loadingstatus = false; // <-- Update here
    state.error = action.payload;
  });

  builder.addCase(getCityNames.pending, (state) => {
    state.loadingstatus = true; // <-- Update here
    state.error = null;
  });
  builder.addCase(getCityNames.fulfilled, (state, action) => {
      state.loadingstatus = false; // <-- Update here
      state.citynames = action.payload;
      state.error = null;
    });
    builder.addCase(getCityNames.rejected, (state, action) => {
      state.loadingstatus = false; // <-- Update here
      state.error = action.payload;
    });


  builder.addCase(getCourseNames.pending, (state) => {
    state.loadingstatus = true; // <-- Update here
    state.error = null;
  });
  builder.addCase(getCourseNames.fulfilled, (state, action) => {
      state.loadingstatus = false; // <-- Update here
      state.coursenames = action.payload;
      state.error = null;
    });
    builder.addCase(getCourseNames.rejected, (state, action) => {
      state.loadingstatus = false; // <-- Update here
      state.error = action.payload;
    });

     builder.addCase(getRoleNames.pending, (state) => {
    state.loadingstatus = true; // <-- Update here
    state.error = null;
  });
  builder.addCase(getRoleNames.fulfilled, (state, action) => {
      state.loadingstatus = false; // <-- Update here
      state.rolenames = action.payload;
      state.error = null;
    });
    builder.addCase(getRoleNames.rejected, (state, action) => {
      state.loadingstatus = false; // <-- Update here
      state.error = action.payload;
    });
  }

  });

  export const selectStudentStatus=(state)=>state.student;
 export const { logout } = studentSlice.actions;

export default studentSlice.reducer; // Ensure to export the loginSlice reducer

