import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../components/defaultSnippets/Card';
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { useParams } from 'react-router-dom';

function EditStaffSalarySlip() {
    const {id}=useParams()
    const [students, setStudents] = useState([]);
    const [master_month, setMasterMonth] = useState('');
    const [staffsalary, setStaffsalary] = useState([]);
    const [totaldays, setTotalDays] = useState(0);
    const [presentdays, setPresentDays] = useState(0);
    const [searchData, setSearchData] = useState({});
    const studentData = useSelector(selectStudentStatus);
    const [formData, setFormData] = useState({});
    const [salaryslipinfo, setsalaryslipinfo] = useState({});
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [attendanceData, setAttendanceData] = useState({});
    const navigate = useNavigate();

    

    const fetchStaffs = async () => {
        const findid = toast.loading("Fetching Students data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.get('/api/staffsalarysearchall', {
                params: searchData // Pass searchData as query parameters
            });
            console.log(res.data.status);
            setStudents(res.data.results);
            setStaffsalary(res.data.salaryinfo);
            setTotalDays(res.data.totaldays);
            setPresentDays(res.data.presentdays);
            setsalaryslipinfo(res.data.salaryslipinfo);
           console.log(res.data.salaryslipinfo);

           
            toast.dismiss(findid); // Dismiss the loading toast when data is fetched successfully
        }
        catch (err) {
            // If request fails
            console.log(err.response);
            if (err.response && err.response.status === 422) {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            } else {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            }
        }
    }
const formatDate = (dateString) => {
  // Split the date string 'DD-MM-YYYY' into parts
  const [day, month, year] = dateString.split('-');

  // Create a new Date object by passing the year, month (subtract 1 since JS months are 0-based), and day
  const date = new Date(year, parseInt(month) - 1, day);

  // Format the date to "short month name" and year (e.g., "Sep 2024")
  const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(date);

  return formattedDate;
};



useEffect(() => {
    // Fetch billing information only once when component mounts
    fetchsalaryinfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const fetchsalaryinfo = async () => {
    try {
        const res = await axios.get('/api/salaryslipinfo/' + id);
        //setIsLoading(false);
        console.log(res.data.master.master_month);
        //const formattedMasterMonth = updateMasterMonth(res.data.staff.master_month); 
        //alert(formattedMasterMonth)
       // setMasterMonth(res.data.staff.master_month)
        const formattedDate = formatDate(res.data.master.master_month);
        setMasterMonth(formattedDate);
        setsalaryslipinfo(res.data.salaryslipinfo);
        //console.log(res.data.invoice);
         //setPostFromData(res.data.invoice);
        // setFormData(res.data.invoice.student);

        // if (res.data.student && res.data.student.profilePic) {
        //     // Set the imagePreview state with the absolute URL of the profile picture
        //     const imagePath = 'http://localhost:8000/'+res.data.student.profilePic;
        //     setImagePreview(imagePath);
        // }
        // // Pre-select state and city based on the fetched data
        // handleStateChange({ target: { value: res.data.student.state } });
        // handleyearChange({ target: { value: res.data.student.academic_year } });
    } catch (err) {
        console.log(err.response);
    }
};

    useEffect(() => {
        if (!searchData.att_date) {
            setIsButtonEnabled(false);
        } else {
            setIsButtonEnabled(true);
        }
    }, [searchData]);

    // const handleChange = (event, studentId, session) => {
    //     const { value } = event.target;
    //     // Update attendance data for the selected session and student
    //     setAttendanceData(prevAttendanceData => ({
    //         ...prevAttendanceData,
    //         [`${studentId}_session${session}`]: value
    //     }));

    // };

  const handleChange = (e, studentId) => {
   // alert(studentId);
  const { name, value } = e.target;

  setsalaryslipinfo(prevState => {
    const student = prevState[studentId];

    // Check if student object exists
    if (!student) {
      console.error(`Student data is missing for studentId: ${studentId}`);
      return prevState;
    }

    // Convert value to number and recalculate sal_payableamount
    const newValue = parseFloat(value) || 0; // Ensure it's a number
    const sal_lessepfamount = parseFloat(student.sal_lessepfamount) || 0;

    return {
      ...prevState,
      [studentId]: {
        ...student,
        [name]: newValue, // Update sal_advance
        sal_payableamount: sal_lessepfamount - newValue, // Recalculate payable amount
      },
    };
  });
};
//     const handleChange = (e,studentId) => {
//     const { name, value } = e.target;
//    setsalaryslipinfo(prevState => ({
//     ...prevState,
//     [studentId]: {
//       ...prevState[studentId],
//       [name]: value,
//      // sal_payableamount:prevState[studentId].sal_lessepfamount-value,
//     }
//   }));
//   };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [name]: value,
        }));
    };

        const handleSearch=(e) => {
    e.preventDefault();
    console.log(searchData);
    fetchStaffs()
}

    const handleDateChange = (selectedDates, dateField) => {
        const selectedDate = selectedDates[0];
        let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
        formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [dateField]: formattedDate,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle submission of attendance data
        console.log(salaryslipinfo);
        const dataToSubmit = {
        ...salaryslipinfo,
        ...searchData
    };

    // Handle submission of attendance data
    console.log(dataToSubmit);
    SendData(dataToSubmit);
     //   SendData()
    };

    const SendData = async(dataToSubmit) =>{
     const id =  toast.loading("Saving Payslip data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.put(`/api/staffsalaryslipupdate/${id}`, dataToSubmit);

        // If request is successful
        console.log(res.data);
        toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/studentattendance');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }

    return (
        <>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Generate Staff Salary for the month of:</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form >
                                <Row className="align-items-center">
                                    
                                  <Col sm="2">
                                    <Form.Group className="mb-3 mb-sm-0">
                                       <input type='text' className='form-control' name="master_month" value={master_month} disabled/>
                                    </Form.Group>
                                </Col>

                                    {/* <Col sm="1">
                                        <div className="text-end">
                                            <Button type="button" id="searchbutton" variant="primary" onClick={handleSearch} disabled={!isButtonEnabled}>Proceed</Button>
                                        </div>
                                    </Col> */}
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <h2 className='text-start'>Salary slip</h2>
                                <thead className='mb-1' style={{backgroundColor:'#2664CF',color:'#fff',padding:'0px', border:'1px solid white', fontSize:'11.5px'}}>
                                    <th style={{border:'1px solid white'}} >Staff Name</th>
                                    <th style={{border:'1px solid white'}}>DESIGN.</th>
                                    <th style={{border:'1px solid white'}}>EPF/HS</th>
                                    <th style={{border:'1px solid white'}}>Fixed Sal.</th>
                                    <th style={{border:'1px solid white'}}>Total<br/>Days</th>
                                    <th style={{border:'1px solid white'}}>Present<br/> Days</th>
                                    <th style={{border:'1px solid white'}}>LOP</th>
                                    <th style={{border:'1px solid white'}}>Less of<br/> LOP</th>
                                    <th style={{border:'1px solid white'}}>BASIC<br/>50%</th>
                                    <th style={{border:'1px solid white'}}>Special Class<br/> 10%</th>
                                    <th style={{border:'1px solid white'}}>HRA<br/>40%</th>
                                    <th style={{border:'1px solid white'}}>Total<br/>Salary</th>
                                    <th style={{border:'1px solid white'}}>EPF<br/> Ded.</th>
                                    <th style={{border:'1px solid white'}}>EPF<br/> Ded. 12%</th>
                                    <th style={{border:'1px solid white'}}>ESI<br/>0.75%</th>
                                    <th style={{border:'1px solid white'}}>Less of <br/>EPF Salary</th>
                                    <th style={{border:'1px solid white'}}>Advance</th>
                                    <th style={{border:'1px solid white'}}>Payable<br/> Salary</th>
                                   

                                </thead>
                                <tbody>
                                     {Object.entries(salaryslipinfo).map(([key, student], studentIndex) => (
                                        <tr  key={student.id} style={{fontSize:'12.5px'}}>
                                            <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="hidden" name="sal_staff_id" value={student.sal_staff_id} /><input type="text" name="sal_staff_name" value={student.sal_staff_name
                                            .toLowerCase()
  .replace(/\b\w/g, (char) => char.toUpperCase())}  disabled style={{width:'150px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_staff_designation" value={student.sal_staff_designation
  .toLowerCase()
  .replace(/\b\w/g, (char) => char.toUpperCase())} disabled style={{width:'150px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_epfhs" value={student.sal_epfhs} disabled style={{width:'50px'}} /></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_fixedsalary" value={student.sal_fixedsalary} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_totaldays" value={student.sal_totaldays} disabled style={{width:'30px'}} /></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_presentdays" value={student.sal_presentdays} disabled style={{width:'30px'}} /></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_lop" value={student.sal_lop} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_lessoflop" value={student.sal_lessoflop} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_basicsalary" value={student.sal_basicsalary} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_specialsalary" value={student.sal_specialsalary} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_hra" value={student.sal_hra} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_totalsalary" value={student.sal_totalsalary} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_epfdeductionamount" value={student.sal_epfdeductionamount} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_epfdeductionpercent" value={student.sal_epfdeductionpercent} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_esiamount" value={student.sal_esiamount} disabled style={{width:'55px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_lessepfamount" value={student.sal_lessepfamount} disabled style={{width:'75px'}}/></td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}>
  <input
    type="text"
    name="sal_advance"
    value={student.sal_advance || ''} // Ensure value from state is displayed
    style={{width:'55px'}}
    onChange={(e) => handleChange(e, student.sal_staff_id)} // Handle input change
  />
</td>
                                             <td style={{borderBottom:'1px solid grey',padding:'5px'}}><input type="text" name="sal_payableamount" value={student.sal_payableamount} disabled style={{width:'75px'}}/></td>
                                        </tr>
                                     ))}
                                </tbody>
                                {/* {students.map((student, studentIndex) => (
                                    <div key={student.id}>
                                       
                                        <Row className='mb-3' style={{borderBottom:'1px solid grey',paddingBottom:'6px'}}>
                                             <Col sm="3">
                                        <h6>{student.first_name}</h6>
                                        </Col>
                                            {[...Array(2)].map((_, sessionIndex) => (
                                                <Col key={sessionIndex} sm="1">
                                                    <Form.Group>
                                                       
                                                        <select
                                                            id={`session_${studentIndex}_${sessionIndex + 1}`}
                                                            name={`session_${studentIndex}_${sessionIndex + 1}`}
                                                            onChange={(event) => handleChange(event, student.id, sessionIndex + 1)}
                                                            style={{ backgroundColor: attendanceData[`${student.id}_session${sessionIndex + 1}`] === 'present' ? 'lightgreen' : attendanceData[`${student.id}_session${sessionIndex + 1}`] === 'absent' ? 'lightcoral' : 'inherit' }}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="present">Present</option>
                                                            <option value="absent">Absent</option>
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))} */}
                                <Button type="submit">Update Salaryslip</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default EditStaffSalarySlip;
