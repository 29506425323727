import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './HeaderL';
import Sidebar from './SidebarL';
import FooterL from './FooterL';
import { useSelector } from 'react-redux';
import { selectStatus } from '../login/loginSlice';
import { useNavigate } from 'react-router-dom';
import TokenChecker from '../../api/TokenChecker';
import axiosapi from '../../api/axios';
import { useState } from 'react';
import Loader from '../../components/defaultSnippets/Loader';

const MainLayout = () => {
  const status = useSelector(selectStatus);
  const navigate = useNavigate();
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axiosapi.get('/api/check-token');
        console.log(response.data.message);
        setIsValidToken(true);
      } catch (error) {
        console.error('Token is expired or invalid');
        localStorage.setItem('token', '');
        // Redirect to the login page or perform other actions
        navigate('/');
      } finally {
        // Set loading to false after the token check is complete
        setIsLoading(false);
      }
    };

    // Check token validity on component mount
    checkTokenValidity();
  }, [navigate]);

  return (
    <div>
      <TokenChecker />
      {isLoading ? (
        <Loader/>
      ) : status.isAuthenticated === 1 && (
        <>
        
          <Sidebar />
          <main className="main-content">
            <div className="position-relative" >
              <Header />
            </div>
            <div className="py-0 conatiner-fluid content-inner px-0">
              <Outlet />
            </div>
            <FooterL />
          </main>
        </>
      )}
    </div>
  );
}

export default MainLayout;
