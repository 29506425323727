import React,{useState,useEffect} from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HostelFeesAdd = () => {
 const [formData, setFormData] = useState({});
const[selectedYear, setSelectedYear] = useState('')

  
  

  useEffect(()=>{
      
    },[]);
//console.log(studentData);
  const navigate = useNavigate();
const handleyearChange = (e) => {
    setSelectedYear(e.target.value);
     setFormData(prevFormData => ({
            ...prevFormData,
            hostelfeeyear: e.target.value // Pre-select city based on fetched data
        }));
  };
 
    // Function to generate options for past four years
const generateYearOptions = () => {
   let temp;
 
       temp=1;
   
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(temp);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};




  const handleChange = (e) => {
  const {id, value } = e.target;

  setFormData({
    ...formData,
    [id]: value, // Updating the specific key in formData
  });
};

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
//    return false;
SendData()
    // Handle form submission with formData
  };

  const SendData = async() =>{
     const id =  toast.loading("Saving Hostel & Mess fees data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post('/api/hostelfeesadd', formData);

        // If request is successful
        console.log(res.data);
        toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/hostelfeesmanagment');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
                <Row>
                   
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Add Hostel & Mess Fees</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                              
                                <Form className="form-horizontal" onSubmit={(e)=>handleSubmit(e)}>
                                  <div className="row">
  
                                   
                                     <Form.Group className="col-md-12 form-group">
                                          <Form.Label htmlFor="first_name" style={{display:'flex'}}>Academic Year:</Form.Label>
                                          <select
                                            name="hostelfeeyear"
                                            id="hostelfeeyear"
                                            className="selectpicker form-control"
                                            data-style="py-0"
                                            value={selectedYear}
                                            onChange={handleyearChange}
                                        >
                                            <option value="">Select</option>
                                            {generateYearOptions()}
                                        </select>
                                      </Form.Group>

                                      <Form.Group className="col-md-12 form-group">
                                          <Form.Label htmlFor="hostelfee"  style={{display:'flex'}}>Hostel fee(s):</Form.Label>
                                          <Form.Control type="text"  id="hostelfee" name="hostelfee" placeholder="" value={formData.hostelfee} onChange={handleChange}/>
                                      </Form.Group>

                                       <Form.Group className="col-md-12 form-group">
                                          <Form.Label htmlFor="messfee"  style={{display:'flex'}}>Mess fee(s):</Form.Label>
                                          <Form.Control type="text"  id="messfee" name="messfee" placeholder="" value={formData.messfee} onChange={handleChange}/>
                                      </Form.Group>

                                  </div>
                                  
                                    
                                  
                                    <Form.Group className="form-group text-end">
                                        <Button type="submit" variant="btn btn-primary">Submit</Button>{' '}
                                        <Link to={'/hostelfeesmanagment'}><Button type="button" variant="btn btn-danger">cancel</Button></Link>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Col>
                </Row>
            </div>
  );
}

export default HostelFeesAdd;
