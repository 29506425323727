import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App';
import { store,persistor } from './store/store';
import { Provider } from 'react-redux';
import { CustomRouter } from "./router/custom-router"; // Correct import statement
import SignIn from './views/login/SignIn'
import { PersistGate } from 'redux-persist/integration/react';
import CustomRouterAuth from "./router/CustomRouterAuth";


const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "*",
    element: <CustomRouterAuth />,
  }]);//, { basename: process.env.PUBLIC_URL }

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor}>
        <App>
          <RouterProvider router={router}></RouterProvider>
       </App>
       </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

