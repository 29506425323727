import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Routes } from 'react-router-dom'; 
import MainLayout from '../views/layouts/MainLayout';
import { CustomRouter } from './custom-router';
import { Route } from 'react-router-dom';

const CustomRouterAuth = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userPermissions = useSelector(state => state.auth.user.data.permissions);
//alert(userPermissions)
  // Define a function to check if the user has permission for a specific route
  // const hasPermission = (requiredPermissions) => {
  //   return requiredPermissions.every(permission => userPermissions.includes(permission));
  // };

  const hasPermission = (requiredPermissions) => {
    console.log('Checking permissions:', requiredPermissions);
    console.log('User permissions:', userPermissions);
    if (!requiredPermissions || requiredPermissions.length === 0) return true;
    return requiredPermissions.every(permission => userPermissions.includes(permission));
  };


  const renderRoutes = (routes) => {
    return routes.map(route => {
      if (route.children) {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.permissions && !hasPermission(route.permissions)
                ? <Navigate to="/unauthorized" />
                : route.element
            }
          >
            {renderRoutes(route.children)}
          </Route>
        );
      }
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.permissions && !hasPermission(route.permissions)
              ? <Navigate to="/unauthorized1" />
              : route.element
          }
        />
      );
    });
  };

  return (
    isAuthenticated ? (
      <Routes>
        {renderRoutes(CustomRouter)}
        <Route path="*" element={<Navigate to="/unauthorized" />} />
      </Routes>
    ) : (
      <Navigate to="/" />
    )
  );
};

export default CustomRouterAuth;
