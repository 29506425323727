import { configureStore,getDefaultMiddleware } from "@reduxjs/toolkit";
import loginReducer, { logout as logoutLogin } from '../views/login/loginSlice';
import courseReducer, { logout as logoutCourse } from '../views/Courses/courseSlice';
import studentReducer, {logout as logoutStudent} from '../views/Students/studentSlice';
import staffReducer, { logout as logoutStaff } from '../views/Staffs/staffSlice';
import feeReducer,{logout as logoutFee} from '../views/Fees/feeSlice';
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import { persistStore } from 'redux-persist';



const persistConfig={
    key:"root",
    version:1,
    storage
}

const reducer=combineReducers({
    auth:loginReducer,
    course:courseReducer,
    student:studentReducer,
    staff:staffReducer,
    fees:feeReducer,
})

const persistedReducer=persistReducer(persistConfig,reducer)

const store = configureStore({ reducer: persistedReducer, middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: { ignoreActions: [], }, }), });
// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware({
//     serializableCheck: {
//       // Ignore all actions by default
//       ignoredActions: ['persist/PERSIST'],
//     },
//   }),
// });


let persistor=persistStore(store);
const logout = () => {
  // Dispatch logout action for both reducers
  store.dispatch(logoutLogin());
  store.dispatch(logoutCourse());
  store.dispatch(logoutStudent());
  store.dispatch(logoutStaff());
  store.dispatch(logoutFee());
};

export { store, persistor, logout };
