import React from 'react'
import { Button } from 'react-bootstrap'
const CustomButton = ({btnType,btnValue,btnVariant,validEmail,buttonState,loading}) => {

  return (
   <>
    <Button
      type={btnType}
      variant={`btn btn-${btnVariant}`}
      className='conthrax'
      disabled={!validEmail || !buttonState || loading}
    >
      {loading && (
        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
      )}
      <span style={{ order: loading ? 1 : 0 }}> {btnValue}</span>
    </Button>

    </>
  )
}

export default CustomButton