import React,{useState} from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
const FeesAdd = () => {
 const [formData, setFormData] = useState({});
 const studentData=useSelector(selectStudentStatus);
   const[duration,setDuration] = useState('');
     const [selectedYear, setSelectedYear] = useState('');
//console.log(studentData);
  const navigate = useNavigate();
const handleyearChange = (e) => {
    setSelectedYear(e.target.value);
     setFormData(prevFormData => ({
            ...prevFormData,
            academic_year: e.target.value // Pre-select city based on fetched data
        }));
  };
 const handleCourseChange = (event) => {
    const selectedCourseId = parseInt(event.target.value);
    setFormData({ ...formData, course_id: selectedCourseId }); // Update formData.city
   // Find the course object with the selectedCourseId
   console.log(selectedCourseId);
   console.log(studentData.coursenames.data.results);
  const selectedCourse = studentData.coursenames.data.results.find(course => course.id === selectedCourseId);
  console.log(selectedCourse);
  if (selectedCourse) {
    // Extract the course_duration from the selected course
    const courseDuration = selectedCourse.course_duration;
    // Now you can do whatever you want with the courseDuration, such as setting it in the state
    console.log(courseDuration);
    setDuration(1);
  }
  };
    // Function to generate options for past four years
const generateYearOptions = () => {
   let temp;
   if(duration===''){
       temp=4;
   }
   else{
       temp=duration;
   }
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(temp);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};


   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
SendData()
    // Handle form submission with formData
  };

  const SendData = async() =>{
     const id =  toast.loading("Saving fees data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post('/api/feesadd', formData);

        // If request is successful
        console.log(res.data);
        toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/feesmanagement');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
                <Row>
                   
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Add new Fees</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                              
                                <Form className="form-horizontal" onSubmit={(e)=>handleSubmit(e)}>
                                  <div className="row">
  
                                    <Form.Group className="col-md-6 form-group">
                                        <Form.Label htmlFor="first_name" style={{display:'flex'}}>Course Name:</Form.Label>
                                          <select
                                                name="course_id"
                                                id="course_id"
                                                className="selectpicker form-control col-md-4"
                                                onChange={handleCourseChange}
                                            >
                                              <option>Select Course</option>
                                                {studentData.coursenames.data.results.map((course, index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                ))}
                                          </select>
                                    </Form.Group>
                                     <Form.Group className="col-md-6 form-group">
                                          <Form.Label htmlFor="first_name" style={{display:'flex'}}>Academic Year:</Form.Label>
                                          <select
                                            name="academic_year"
                                            id="academic_year"
                                            className="selectpicker form-control"
                                            data-style="py-0"
                                            value={selectedYear}
                                            onChange={handleyearChange}
                                        >
                                            <option value="">Select</option>
                                            {generateYearOptions()}
                                        </select>
                                      </Form.Group>

                                  </div>
           
    


                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">GQ/FG:</Form.Label>
                                        <Col sm="9">
                                        
                                          <Form.Control type="text"  id="gq_fg" name="gq_fg" placeholder="" value={formData.gq_fg} onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>
                                     <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">GQ/NFG:</Form.Label>
                                        <Col sm="9">
                                        
                                          <Form.Control type="text"  id="gq_nfg" name="gq_nfg" placeholder="" value={formData.gq_nfg} onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">MQ - OC / BC / BCM / MBC:</Form.Label>
                                        <Col sm="9">
                                        
                                          <Form.Control type="text"  id="mq_oc" name="mq_oc" placeholder="" value={formData.mq_oc} onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">MQ - SC / SCA / ST / SCC:</Form.Label>
                                        <Col sm="9">
                                        
                                          <Form.Control type="text"  id="mq_sc" name="mq_sc" placeholder="" value={formData.mq_sc} onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">Admission Fee:</Form.Label>
                                        <Col sm="9">
                                        
                                          <Form.Control type="text"  id="fee_admission" name="fee_admission" placeholder="" value={formData.fee_admission} onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">Other Fee:</Form.Label>
                                        <Col sm="9">
                                        
                                          <Form.Control type="text"  id="fee_other" name="fee_other" placeholder="" value={formData.fee_other} onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">Admission Incentive - OC / BC / BCM / MBC:</Form.Label>
                                        <Col sm="9">
                                        
                                          <Form.Control type="text"  id="incentive_oc" name="incentive_oc" placeholder="" value={formData.incentive_oc} onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="form-group">
                                        <Form.Label column sm="2" className="control-label mb-0 text-start" htmlFor="course_duration">Admission Incentive - SC / SCA / ST / SCC:</Form.Label>
                                        <Col sm="9">
                                        
                                          <Form.Control type="text"  id="incentive_sc" name="incentive_sc" placeholder="" value={formData.incentive_sc} onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>
                                  
                                    <Form.Group className="form-group text-end">
                                        <Button type="submit" variant="btn btn-primary">Submit</Button>{' '}
                                        <Link to={'/feesmanagement'}><Button type="button" variant="btn btn-danger">cancel</Button></Link>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Col>
                </Row>
            </div>
  );
}

export default FeesAdd;
