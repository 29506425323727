import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../components/defaultSnippets/Card';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';

const HostelFeesEdit = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({});
    const [selectedYear, setSelectedYear] = useState('');
    const [busstopList, setBusstopList] = useState([]);
 const navigate = useNavigate();
    

    const fetchFeesMaster = async () => {
        try {
            const res = await axios.get(`/api/hostelfees/${id}`);
            const stopData = res.data.feedetails;
            setSelectedYear(stopData.hostelfeeyear);
            setFormData(res.data.feedetails);
            // return false;
        //      setFormData(prevFormData => ({
        //     ...prevFormData,
        //     hostelfeeyear: stopData.hostelfeeyear // Ensure busfeeacyear is included
        // }));
            setIsLoading(false);
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred");
        }
    };

    useEffect(() => {
        fetchFeesMaster();
    }, [id]);

   const handleyearChange = (e) => {
        const newYear = e.target.value;
        setSelectedYear(newYear);

        setFormData(prevFormData => ({
            ...prevFormData,
            hostelfeeyear: newYear
        }));
    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i <= currentYear - 2020; i++) {
            const startYear = currentYear - i;
            const endYear = startYear + 1; // Use 1 to show the next year
            years.push(`${startYear}-${endYear}`);
        }
        return years.map(year => (
            <option key={year} value={year}>
                {year}
            </option>
        ));
    };

     const handleChange = (e) => {
  const {id, value } = e.target;
//alert(id+"->"+value)
  setFormData({
    ...formData,
    [id]: value, // Updating the specific key in formData
  });
};


    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

            Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
    });
        formDataToSend.append('_method', 'PUT');
        formDataToSend.delete('hostelfeeyear');
        formDataToSend.append('hostelfeeyear',selectedYear);
        //console.log(formDataToSend)
       // return false;
        SendData(formDataToSend);
    };

    const SendData = async (formDataToSend) => {
        const loaderid = toast.loading("Updating data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.post(`/api/hostelfeesupdate/${id}`, formDataToSend);
            toast.update(loaderid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
            navigate('/hostelfeesmanagment');
        } catch (err) {
            toast.update(loaderid, { render: err.response?.data?.message || "An error occurred", type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    return (
        <div>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Edit Bus Fees</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {isLoading ? (
                                <p>Loading Data....</p>
                            ) : (
                                <Form className="form-horizontal" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <Form.Group className="col-md-6 form-group">
                                            <Form.Label htmlFor="academic_year" style={{ display: 'flex' }}>Academic Year:</Form.Label>
                                            <select
                                                name="busfeeacyear"
                                                id="busfeeacyear"
                                                className="selectpicker form-control"
                                                data-style="py-0"
                                                value={selectedYear}
                                                onChange={handleyearChange}
                                            >
                                                <option value="">Select</option>
                                                {generateYearOptions()}
                                            </select>
                                        </Form.Group>
                                        <Form.Group className="col-md-12 form-group">
                                          <Form.Label htmlFor="hostelfee"  style={{display:'flex'}}>Hostel fee(s):</Form.Label>
                                          <Form.Control type="text"  id="hostelfee" name="hostelfee" placeholder="" value={formData.hostelfee} onChange={handleChange}/>
                                      </Form.Group>

                                       <Form.Group className="col-md-12 form-group">
                                          <Form.Label htmlFor="messfee"  style={{display:'flex'}}>Mess fee(s):</Form.Label>
                                          <Form.Control type="text"  id="messfee" name="messfee" placeholder="" value={formData.messfee} onChange={handleChange}/>
                                      </Form.Group>
                                    </div>
                                    
                                    <Form.Group className="form-group text-end">
                                        <Button type="submit" variant="btn btn-primary">Submit</Button>{' '}
                                        <Link to={'/hostelfeesmanagment'}><Button type="button" variant="btn btn-danger">Cancel</Button></Link>
                                    </Form.Group>
                                </Form>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default HostelFeesEdit;
