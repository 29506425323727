import React,{useState,useEffect} from 'react'
import { Form, Nav , Tab, Row, Col, InputGroup,Button,Image } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link,useParams } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import avatars1 from '../../assets/images/avatars/01.png'
import avatars2 from '../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../assets/images/avatars/avtar_5.png'
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
const BillingEdit = () => {
    const {id}=useParams()
    const[isLoading, setIsLoading]=useState(true);
    const [formData, setFormData] = useState({});
    const [postfromData, setPostFromData] = useState({ admissionfee: 0,
    tutionfee: 0,
    specialfee: 0,
    labfee: 0,
    libraryfee: 0,
    stationaryfee: 0,
    physedfee: 0,
    otherfee: 0,
    machineriesfee: 0,
    compfee: 0,
    examfee: 0,
    penaltyfee: 0,
    certfee: 0,
    miscfee: 0,
    totalamount: 0});
   const[imagePreview,setImagePreview]=useState('');
 const studentData=useSelector(selectStudentStatus);

   const[duration,setDuration] = useState('');
  
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const navigate = useNavigate();



   const handlePostChange = (e) => {
    const { name, value } = e.target;
    setPostFromData({
      ...postfromData,
      [name]: value,
    });
  };

  // Function to calculate total amount

  const calculateTotalAmount = () => {
    const fieldsToInclude = [
        postfromData.admissionfee,
        postfromData.tutionfee,
        postfromData.specialfee,
        postfromData.labfee,
        postfromData.libraryfee,
        postfromData.stationaryfee,
        postfromData.physedfee,
        postfromData.otherfee,
        postfromData.machineriesfee,
        postfromData.compfee,
        postfromData.examfee,
        postfromData.penaltyfee,
        postfromData.certfee,
        postfromData.miscfee
    ];

    // Initialize accumulator
    let total = 0;

    // Iterate through the specified fields
    fieldsToInclude.forEach(field => {
        // Convert the field value to a number using parseFloat
        const fieldValue = parseFloat(field);

        // Check if fieldValue is a valid number (not NaN)
        if (!isNaN(fieldValue)) {
            // Add fieldValue to the total
            total += fieldValue;
        }
    });

    return total;
};

useEffect(() => {
    // Fetch billing information only once when component mounts
    fetchBilling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  
 useEffect(() => {
        // Check if searchData contains both 'current_program' and 'current_year' and they are not empty
        
        setPostFromData({
      ...postfromData,
    totalamount: calculateTotalAmount()
    });
        
       
    }, [postfromData.admissionfee, postfromData.tutionfee, postfromData.specialfee, postfromData.labfee, postfromData.libraryfee, postfromData.stationaryfee, postfromData.physedfee, postfromData.otherfee, postfromData.machineriesfee, postfromData.compfee, postfromData.examfee, postfromData.penaltyfee, postfromData.certfee, postfromData.miscfee]);

const fetchBilling = async () => {
    try {
        const res = await axios.get('/api/invoice/' + id);
        setIsLoading(false);
        console.log(res.data.invoice);
         setPostFromData(res.data.invoice);
         setFormData(res.data.invoice.student);

        // if (res.data.student && res.data.student.profilePic) {
        //     // Set the imagePreview state with the absolute URL of the profile picture
        //     const imagePath = 'http://localhost:8000/'+res.data.student.profilePic;
        //     setImagePreview(imagePath);
        // }
        // // Pre-select state and city based on the fetched data
        // handleStateChange({ target: { value: res.data.student.state } });
        // handleyearChange({ target: { value: res.data.student.academic_year } });
    } catch (err) {
        console.log(err.response);
    }
};

  const generateYearOptions = () => {
   let temp;
   if(duration===''){
       temp=4;
   }
   else{
       temp=duration;
   }
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(temp);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};

const handleSubmit = (e) => {
    e.preventDefault();
      const formDataToSend = new FormData();

    // Append all form data fields to formDataToSend
    Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
    });

    // Append the profilePic file to formDataToSend
    formDataToSend.append('_method','PUT');
SendData(formDataToSend)
    // Handle form submission with formData
  };

  const SendData = async(formDataToSend) =>{
     const loaderid =  toast.loading("Updating data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.put(`/api/invoiceupdate/${id}`, postfromData);

        // If request is successful
        console.log(res.data);
        toast.update(loaderid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/billing');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
               

                <Row>
               <Col xl="3" lg="4" className="">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Student Details</h4>
                        </div>
                     </Card.Header>
                     <Card.Body className="text-start">
                        <Form>
                           <Form.Group className="form-group">
                                <div className="profile-img-edit position-relative">
                                    {imagePreview ? (
                                        <Image src={imagePreview} alt="profile-preview" className="profile-pic rounded avatar-100" />
                                    ) : (
                                        <>
                                            <Image className="theme-color-default-img profile-pic rounded avatar-100" src={avatars1} alt="profile-pic"/>
                                            <Image className="theme-color-purple-img profile-pic rounded avatar-100" src={avatars2} alt="profile-pic"/>
                                            <Image className="theme-color-blue-img profile-pic rounded avatar-100" src={avatars3} alt="profile-pic"/>
                                            <Image className="theme-color-green-img profile-pic rounded avatar-100" src={avatars5} alt="profile-pic"/>
                                            <Image className="theme-color-yellow-img profile-pic rounded avatar-100" src={avatars6} alt="profile-pic"/>
                                            <Image className="theme-color-pink-img profile-pic rounded avatar-100" src={avatars4} alt="profile-pic"/>
                                        </>
                                    )}
                                    
                                </div>

                              {/* <div className="img-extension mt-3">
                                 <div className="d-inline-block align-items-center">
                                    <span>Only</span>{' '}
                                    <Link to="#">.jpg</Link>{' '}
                                    <Link to="#">.png</Link>{' '}
                                    <Link to="#">.jpeg</Link>{' '}
                                    <span>allowed</span>
                                 </div>
                              </div> */}
                           </Form.Group>
                           <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="pno">Student ID Number:</Form.Label>
                                    <Form.Control class="form-control form-control-sm" type="text"  id="student_id_number" name="student_id_number" placeholder="" value={formData.student_id_number} disabled/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="city">Aadhar Number:</Form.Label>
                                    <Form.Control class="form-control form-control-sm" type="text"  id="government_id" name="government_id" placeholder="" value={formData.government_id} disabled/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="lname">Date of Birth:</Form.Label>
                                    <Form.Control class="form-control form-control-sm" type="text"  id="date_of_birth" name="date_of_birth" placeholder="" value={formData.date_of_birth} disabled/>
                                
                                 </Form.Group>
                                 
                                
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="current_program">Current Course:</Form.Label>
                                    {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                     <select
                                        name="current_program"
                                        id="current_program"
                                        className="selectpicker form-control form-control-sm"
                                       value={formData.current_program}
                                       disabled
                                    >
                                        <option>Select Course</option>
                                        {studentData.coursenames.data.results.map((course, index) => (
                                        <option key={index} value={course.id}>
                                            {course.coursename}
                                        </option>
                                        ))}
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
      <Form.Label>Academic Year:</Form.Label>
      <select
        name="academic_year"
        id="academic_year"
        className="selectpicker form-control form-control-sm"
        data-style="py-0"
        value={formData.academic_year}
       disabled
      >
        <option value="">Select</option>
        {generateYearOptions()}
      </select>
    </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="admission_date">Admission Date:</Form.Label>
                                     <Form.Control class="form-control form-control-sm" type="text"  id="admission_date" name="admission_date" placeholder="" value={formData.admission_date} disabled/> 
                                 
                                 </Form.Group>
                                  <Form.Group className="col-md-12 form-group">
                                    <Form.Label>Current Year:</Form.Label>
                                    <select name="current_year" id="current_year" className="selectpicker form-control form-control-sm" data-style="py-0"  value={formData.current_year} disabled>
                                       <option>Select</option>
                                       <option>I</option>
                                       <option>II</option>
                                       <option >III</option>
                                       <option >IV</option>
                                    </select>
                                 </Form.Group>
                                                           
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
            <Col xl="9" lg="8">
    <Card>
        <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
                <h4 className="card-title">Billing Information</h4>
            </div>
        </Card.Header>
        <Card.Body className="text-start">
            <div className="new-user-info">
                <Form className="form-horizontal" onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="Bill_no">Bill Number:</Form.Label>
                        <div className="col-md-6 mb-2" >
                            <input className="form-control form-control-sm" type="text" id="invoiceno" name="invoiceno" placeholder="" value={postfromData.invoiceno} onChange={handlePostChange} />
                            <input type="hidden" id="invoicetoid" name="invoicetoid" placeholder="" value={postfromData.invoicetoid} onChange={handlePostChange}></input>
                            <input type="hidden" id="invoicetoname" name="invoicetoname" placeholder="" value={postfromData.invoicetoname} onChange={handlePostChange}></input>
                            <input type="hidden" id="invoiceyear" name="invoiceyear" placeholder="" value={postfromData.invoiceyear} onChange={handlePostChange}></input>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Admission Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="admissionfee" name="admissionfee" placeholder="" value={postfromData.admissionfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Tuition Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="tutionfee" name="tutionfee" placeholder="" value={postfromData.tutionfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Special Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="specialfee" name="specialfee" placeholder="" value={postfromData.specialfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Laboratory Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="labfee" name="labfee" placeholder="" value={postfromData.labfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Library Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="libraryfee" name="libraryfee" placeholder="" value={postfromData.libraryfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Stationary Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="stationaryfee" name="stationaryfee" placeholder="" value={postfromData.stationaryfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Physical Education Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="physedfee" name="physedfee" placeholder="" value={postfromData.physedfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Other Facilities Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="otherfee" name="otherfee" placeholder="" value={postfromData.otherfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Machineries Depm. Misc.etc (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="machineriesfee" name="machineriesfee" placeholder="" value={postfromData.machineriesfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Computer Science Lab Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="compfee" name="compfee" placeholder="" value={postfromData.compfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Examination Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="examfee" name="examfee" placeholder="" value={postfromData.examfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Penalty Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="penaltyfee" name="penaltyfee" placeholder="" value={postfromData.penaltyfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Certificate Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-sm" type="text" id="certfee" name="certfee" placeholder="" value={postfromData.certfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-4" htmlFor="last_name">Miscellaneous Fee (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-4">
                            <input className="form-control form-control-sm" type="text" id="miscfee" name="miscfee" placeholder="" value={postfromData.miscfee} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-3 mb-2" htmlFor="last_name">Grand Total (&#8377;):</Form.Label>
                        <div className="col-md-6 mb-2">
                            <input className="form-control form-control-lg" type="text" id="totalamount" name="totalamount" placeholder="" value={postfromData.totalamount} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                     <Form.Group className="form-group text-end">
                        <Button type="submit" variant="btn btn-primary">Update</Button>{' '}
                            <Link to={'/billing'}><Button type="button" variant="btn btn-danger">cancel</Button></Link>
                    </Form.Group>
                   
                </Form>
            </div>
        </Card.Body>
    </Card>
</Col>


            </Row>
            </div>
  );
}

export default BillingEdit;
