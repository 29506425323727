import React, { useState } from 'react'
import { Row, Col, Image, Form, Button, ListGroup, } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../components/Card'
import tracerlogo from '../../assets/images/auth/logo_tracer.png'
import CustomButton from '../../components/Snippets/CustomButton'
import auth1 from '../../assets/images/auth/01.jpg'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {login,loginUser,selectStatus} from './loginSlice'
import { logout } from '../../store/store'
import { useEffect } from 'react'
const SignIn = () => {
  
   const[email, setEmail]=useState('')
   const[password, setPassword]=useState('')
   const[validEmail, setValidEmail]=useState(false)
   const[buttonState, setButtonState]=useState(false)
   const status=useSelector(selectStatus)
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const validateEmail=()=>{
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(email);
      setValidEmail(isValid);
      validatePassword()
   }

   const validatePassword=()=>{
      // const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      // const isValid = passwordRegex.test(password);
       !password.length ? setButtonState(false) : setButtonState(true)
      
   }
useEffect(() => {
   logout();
},[])

   const handleSubmit = (e) => 
   {
         e.preventDefault()
         const data = new FormData(e.currentTarget);
         const id =  toast.loading("Authenticating...",{
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
      
         if(validEmail && password.length){
            dispatch(loginUser({email, password})).then(result=>
            {
               console.log(result);
               if(result.payload.status===200){
                  toast.update(id, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
                  setEmail('')
                  setPassword('')
               //console.log("ready to navigate")
                navigate('/dashboard')
               }
               else
               {
                  if(result.payload.status === 401)
                  {
                  toast.update(id, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
                  }
               }
            })
         }
         else
         {
            toast.error('Please fill all the fields')
         }
   }

   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <Col md="6"  style={{borderRight:'1px solid #ededed'}}>
                  <Row className="justify-content-center">
                     <Col md="10" >
                        <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                           <Card.Body>
                              <Image src={tracerlogo} className="d-flex align-items-center mb-4" alt="images" style={{margin:'0 auto',textAlign:'center',width:'100%'}} />
                              <h2 className="mb-2 text-center conthrax">Sign In</h2>
                              <p className="text-center">Login to stay connected.</p>
                              <Form onSubmit={(e)=>handleSubmit(e)}>
                                 <Row>
                                    <Col lg="12">
                                       <Form.Group className="form-group">
                                          {/* <Form.Label htmlFor="email" className="">Username</Form.Label> */}
                                          <Form.Control type="email" className="" value={email} onChange={(e)=>setEmail(e.target.value)} onKeyUpCapture={validateEmail} id="email" name="email" aria-describedby="email" placeholder="Username" autoComplete='off' autoFocus required/>
                                       </Form.Group>
                                    </Col>
                                    <Col lg="12" className="">
                                       <Form.Group className="form-group">
                                          {/* <Form.Label htmlFor="password" className="">Password</Form.Label> */}
                                          <Form.Control type="password" className="" value={password} onChange={(e)=>setPassword(e.target.value)} onKeyUpCapture={validatePassword} id="password" name="password" aria-describedby="password" placeholder="Password" required/>
                                       </Form.Group>
                                    </Col>
                                    {/* <Col lg="12" className="d-flex justify-content-between">
                                       <Form.Check className="form-check mb-3">
                                          <Form.Check.Input type="checkbox" id="customCheck1" />
                                          <Form.Check.Label htmlFor="customCheck1">Remember Me</Form.Check.Label>
                                       </Form.Check>
                                    </Col> */}
                                 </Row>
                                 <div className="d-flex justify-content-center">
                                   
                                     <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          
                                          <CustomButton btnType="submit" btnValue="Login" btnVariant="primary" validEmail={validEmail} buttonState={buttonState} loading={status.loadingstatus}/>
                                       </ListGroup.Item>
                                       {/* <ListGroup.Item as="li" className="border-0 pb-0">
                                          <CustomButton btnType="button" btnValue="Employee" btnVariant="primary" validEmail={validEmail} buttonState={buttonState}/>
                                       </ListGroup.Item> */}
                                   </ListGroup>
                                 </div>
                                 <ToastContainer />
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
               </Col>
               <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth1} className="Image-fluid gradient-main" alt="images" style={{objectFit:'cover'}} />
                  {/* style={{objectFit:'none'}} */}
               </Col>
            </Row>
         </section>
      </>
   )
}


export default SignIn
