import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  feesdetails:{}
 
};

const feeSlice = createSlice({
  name: "fees",
  initialState,
  reducers: {
   setfeesdetails: (state, action) => {
      state.feesdetails = action.payload
    },
   logout: (state) => {
      return initialState; // Reset state to initial state upon logout
    }

   
  },

});

export const { logout,setfeesdetails } = feeSlice.actions;
export const selectFeesStatus=(state)=>state.fees;
export default feeSlice.reducer; // Ensure to export the loginSlice reducer