import axios from "axios";

const axiosapi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// Add an interceptor to include the Authorization header for every request
axiosapi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Assuming your token is stored in localStorage

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
   
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosapi.interceptors.request.use(
  (config) => {
    // Check if the request data is FormData
    if (config.data instanceof FormData) {
      // Set Content-Type header for FormData requests
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosapi;