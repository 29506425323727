import React,{useState,useEffect} from 'react'
import { Form, Nav , Table,Tab, Row, Col, InputGroup,Button,Image } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import avatars1 from '../../assets/images/avatars/01.png'
import avatars2 from '../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../assets/images/avatars/avtar_5.png'
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import { useDispatch } from 'react-redux';
import { setfeesdetails,selectFeesStatus } from '../Fees/feeSlice';
import { FaEye  } from 'react-icons/fa';

const TranscertAdd = () => {
 const [formData, setFormData] = useState({});
 const [postfromData, setPostFromData] = useState({});
 const [searchData, setSearchData] = useState({});
   const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
const[data, setData] = useState([]);
   const[imagePreview,setImagePreview]=useState('');
 const studentData=useSelector(selectStudentStatus);
 const feesData=useSelector(selectFeesStatus);
 const [selectedYear, setSelectedYear] = useState('');
 const[balancetotal,setBalanceTotal]=useState(0);
   const[duration,setDuration] = useState('');
   const dispatch=useDispatch();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const navigate = useNavigate();



  const handlePostChange = (e) => {
    const { name, value } = e.target;
    setPostFromData({
      ...postfromData,
      [name]: value,
    });
  };

  


  const handleSearchChange = (e) => {
   setData([])
   
   console.log(formData);
    const { name, value } = e.target;
     if(name=='current_program' || name=='current_year')
     {
        setFormData({student_id_number:'',government_id:'',date_of_birth:'',current_program:'',academic_year:'',admission_date:'',current_year:'',})
   setBalanceTotal(0)
        setSearchData(prevSearchData => {
            const updatedSearchData = {
                ...prevSearchData,
                [name]: value
            };
            
            if (name === 'current_program' || name === 'current_year') {
                updatedSearchData.current_student = ''; // Emptying current_student if condition is true
            }
            
            return updatedSearchData;
            });
     }
    setSearchData(prevSearchData => ({
        ...prevSearchData,
        [name]: value,
    }));
    
  console.log(searchData); // Log name and value separately
    // Check if searchData contains both 'current_program' and 'current_year' and they are not empty
   

   
};
 useEffect(() => {
        // Check if searchData contains both 'current_program' and 'current_year' and they are not empty
       
    //     setPostFromData({
    //   ...postfromData,
    //   totalamount: calculateTotalAmount() // Update total amount in state
    // });
   // 
        if(!searchData.current_student)
        {
           
             if (searchData.current_program && searchData.current_year) {
                
            console.log("Search Data:", searchData);
            const searchButton = document.getElementById('searchbutton');
            const saveButton = document.getElementById('savebutton');
            if (searchButton) 
            {
                searchButton.disabled = true;
                searchButton.onclick = ''; // Remove click handler

                saveButton.disabled = true;
               
            }
            
            GetMatches()
            }
            else if(searchData.studentid && searchData.studentid.length > 0) 
            {
                 const searchButton = document.getElementById('searchbutton');
                const saveButton = document.getElementById('savebutton');
                if (searchButton) 
                {
                    searchButton.disabled = false;
                    searchButton.onclick = handleSearch;
                    saveButton.disabled = false;
                }
            }
            else
            {
              
                const searchButton = document.getElementById('searchbutton');
                const saveButton = document.getElementById('savebutton');
                if (searchButton) 
                {
                    searchButton.disabled = true;
                    searchButton.onclick = ''; // Remove click handler

                    saveButton.disabled = true;
                
                }
            }
        }
        else
        {
           // alert("sdf")
           const searchButton = document.getElementById('searchbutton');
            const saveButton = document.getElementById('savebutton');
            if (searchButton) 
            {
                searchButton.disabled = false;
                searchButton.onclick = handleSearch;
                saveButton.disabled = false;
            }
        }
      
       
    }, [searchData,postfromData.admissionfee, postfromData.tutionfee, postfromData.specialfee, postfromData.labfee, postfromData.libraryfee, postfromData.stationaryfee, postfromData.physedfee, postfromData.otherfee, postfromData.machineriesfee, postfromData.compfee, postfromData.examfee, postfromData.penaltyfee, postfromData.certfee, postfromData.miscfee]);

    const getFeedetails=async()=>{
          await axios.get('/api/feeslist')
    .then(res=> {
      console.log(res.data.results);
      // const jsonData = JSON.stringify(res.data.results);
     dispatch(setfeesdetails(res.data.results));
    });
    }
  const generateYearOptions = () => {
   let temp;
   if(duration===''){
       temp=4;
   }
   else{
       temp=duration;
   }
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(temp);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};

const handleSearch=(e) => {
    e.preventDefault();
    console.log(searchData);
    findData()
}

const GetMatches = async () => {
    const matchid = toast.loading("Fetching Student data...", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });

    try {
        const res = await axios.get('/api/tcstudentsearchall', {
            params: searchData // Pass searchData as query parameters
        });

        // If request is successful
        console.log(res.data);
        const studentData = res.data.results;
        if (Object.keys(studentData).length === 0) {
    console.log("Results object is empty.");
    const dropdown = document.getElementById('current_student'); // Replace 'dropdownId' with the actual ID of your dropdown

        // Clear existing options
        dropdown.innerHTML = '';

        // Add an empty option at first
        const emptyOption = document.createElement('option');
        emptyOption.value = ''; // Set value to empty string
        emptyOption.textContent = 'Select Student'; // Set text content to empty string
        dropdown.appendChild(emptyOption);
     toast.update(matchid, { render: "No results", type: "info", isLoading: false, autoClose: 1000, theme: "light" });
} else {
    console.log("Results object is not empty.");
const dropdown = document.getElementById('current_student'); // Replace 'dropdownId' with the actual ID of your dropdown

        // Clear existing options
        dropdown.innerHTML = '';

        // Add an empty option at first
        const emptyOption = document.createElement('option');
        emptyOption.value = ''; // Set value to empty string
        emptyOption.textContent = 'Select Student'; // Set text content to empty string
        dropdown.appendChild(emptyOption);

        // Populate dropdown with options from res.data.results
        studentData.forEach(student => {
            // Create a new option element
            const option = document.createElement('option');
            // Set the value attribute to the student's id
            option.value = student.id;
            // Set the text content to the student's first name
            option.textContent = "("+student.student_id_number+") "+student.first_name;
            // Append the option to the dropdown
            dropdown.appendChild(option);
        });
 getFeedetails()
        toast.update(matchid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
       
}


        
        // navigate('/coursemanagement');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(matchid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(matchid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
}

 const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter the data based on the search term
  const filteredData = data.filter((item) => {
    console.log(item)
    return (
    //   item.invoiceno.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //   item.invoicetoname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //   item.created_at.toLowerCase().includes(searchTerm.toLowerCase())|| 
       item.student_id_number.toLowerCase().includes(searchTerm.toLowerCase()) 
     
    
    );
  });

  // Recalculate total entries and total pages based on filtered data
  const totalEntries = filteredData.length;
  const totalPages = Math.ceil(totalEntries / itemsPerPage);

  // Slice the filtered data for the current page
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const firstEntryIndex = Math.min((currentPage - 1) * itemsPerPage + 1, totalEntries);
  const lastEntryIndex = Math.min(currentPage * itemsPerPage, totalEntries);

const handleDateChange = (selectedDates, dateField) => {
    // const selectedDate = selectedDates[0];
    // setFormData({
    //   ...formData,
    //   [dateField]: selectedDate,
    // });
    const selectedDate = selectedDates[0];
    let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
    formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
    setPostFromData({
        ...postfromData,
        [dateField]: formattedDate, // Set the formatted date in the formData
    });
  };
const findData = async() =>{
     const findid =  toast.loading("Fetching Student data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
    
         const res = await axios.get('/api/tcstudentsearchspecific', {
            params: searchData // Pass searchData as query parameters
        });

        // If request is successful
        console.log(res.data);
        const studentData = res.data.results;
       
      // setData(res.data.prevpayments);
// console.log(feesData);
        // Fill input fields with student data
        // document.getElementById('student_id_number').value = studentData.student_id_number;
        // document.getElementById('government_id').value = studentData.government_id;
// console.log(studentData.academic_year)
// const firstPart = studentData.academic_year.split('-')[0];
// const filteredData = feesData.feesdetails.filter(item => item.academic_year.startsWith(firstPart));

// console.log(filteredData);
        setFormData(res.data.results);
          if (res.data.results && res.data.results.profilePic) {
            // Set the imagePreview state with the absolute URL of the profile picture
            const imagePath = process.env.REACT_APP_API_BASE_URL+'/'+res.data.results.profilePic;
            setImagePreview(imagePath);
        }
       //  setBalanceTotal(res.data.balancetotal);
        setPostFromData({tc_studid:studentData.student_id_number, tc_studname: studentData.first_name+" "+studentData.last_name,tc_guardian:studentData.parent_guardian_name,tc_natrel:studentData.nationality,tc_caste:studentData.caste,tc_dob:studentData.date_of_birth,tc_gender:studentData.gender,tc_doa:studentData.admission_date,tc_adcourse:studentData.current_program,tc_course:studentData.current_program});

        toast.update(findid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
       // navigate('/coursemanagement');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }

const handleSubmit = (e) => {
    e.preventDefault();
    console.log(postfromData);
SendData()
    // Handle form submission with formData
  };

  const SendData = async() =>{
     const id =  toast.loading("Saving data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        if(postfromData.tc_dob!=null)
        {
            const res = await axios.post('/api/transcertadd', postfromData);

            // If request is successful
            console.log(res.data);
            toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
            navigate('/transfercertificate');
        }
        else
        {
             toast.update(id, { render: "Enter Date of Birth", type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
             return
        }
        
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Search Student</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row className="align-items-center">
                                        <Col md="3">
                                            <Form.Group as={Row} className="mb-3 mb-sm-0">
                                                {/* <Form.Label column sm="2" htmlFor="email" className="control-label text-start">Student ID:</Form.Label> */}
                                                <Col sm="12">
                                                    <Form.Control name="studentid" id="studentid" placeholder="Enter Student ID" value={searchData.studentid} onChange={handleSearchChange} required />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col sm="1">
                                            <div className="text-end">
                                                <Button type="submit" variant="primary">Search</Button>
                                            </div>
                                        </Col> */}
                                        |
                                         <Col sm="3">
                                            <Form.Group className="mb-3 mb-sm-0">
                                                {/* <Form.Label htmlFor="current_program">Current Course:</Form.Label> */}
                                                {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                                <select
                                                    name="current_program"
                                                    id="current_program"
                                                    className="selectpicker form-control"
                                                    value={searchData.current_program}
                                                    
                                                    onChange={handleSearchChange}

                                                >
                                                    <option value=''>Select Course</option>
                                                    {studentData.coursenames.data.results.map((course, index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                    ))}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="2">
                                             <Form.Group className="mb-3 mb-sm-0">
                                                {/* <Form.Label>Current Year:</Form.Label> */}
                                                <select name="current_year" id="current_year" className="selectpicker form-control" data-style="py-0"  value={searchData.current_year} onChange={handleSearchChange}>
                                                <option>Select Year</option>
                                                <option>I</option>
                                                <option>II</option>
                                                <option >III</option>
                                                <option >IV</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Group className="mb-3 mb-sm-0">
                                               
                                                <select
                                                    name="current_student"
                                                    id="current_student"
                                                    className="selectpicker form-control"
                                                    value={searchData.current_student}
                                                    
                                                    onChange={handleSearchChange}
                                                
                                                >
                                                    <option>Select Student</option>
                                                  
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="1">
                                            <div className="text-end">
                                                <Button type="button" id="searchbutton" variant="primary"  onClick={isButtonEnabled ? handleSearch : null}
                disabled={!isButtonEnabled}>Search</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
               <Col xl="3" lg="4" className="">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Student Details</h4>
                        </div>
                     </Card.Header>
                     <Card.Body className="text-start">
                        <Form>
                           <Form.Group className="form-group">
                                <div className="profile-img-edit position-relative">
                                    {imagePreview ? (
                                        <Image src={imagePreview} alt="profile-preview" className="profile-pic rounded avatar-100" />
                                    ) : (
                                        <>
                                            <Image className="theme-color-default-img profile-pic rounded avatar-100" src={avatars1} alt="profile-pic"/>
                                            <Image className="theme-color-purple-img profile-pic rounded avatar-100" src={avatars2} alt="profile-pic"/>
                                            <Image className="theme-color-blue-img profile-pic rounded avatar-100" src={avatars3} alt="profile-pic"/>
                                            <Image className="theme-color-green-img profile-pic rounded avatar-100" src={avatars5} alt="profile-pic"/>
                                            <Image className="theme-color-yellow-img profile-pic rounded avatar-100" src={avatars6} alt="profile-pic"/>
                                            <Image className="theme-color-pink-img profile-pic rounded avatar-100" src={avatars4} alt="profile-pic"/>
                                        </>
                                    )}
                                    
                                </div>

                              {/* <div className="img-extension mt-3">
                                 <div className="d-inline-block align-items-center">
                                    <span>Only</span>{' '}
                                    <Link to="#">.jpg</Link>{' '}
                                    <Link to="#">.png</Link>{' '}
                                    <Link to="#">.jpeg</Link>{' '}
                                    <span>allowed</span>
                                 </div>
                              </div> */}
                           </Form.Group>
                          
                           <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="pno">Student ID Number:</Form.Label>
                                    <Form.Control class="form-control form-control-sm" type="text"  id="student_id_number" name="student_id_number" placeholder="" value={formData.student_id_number} disabled/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="city">Aadhar Number:</Form.Label>
                                    <Form.Control class="form-control form-control-sm" type="text"  id="government_id" name="government_id" placeholder="" value={formData.government_id} disabled/>
                                 </Form.Group>
                                 
                                
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="current_program">Current Course:</Form.Label>
                                    {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                     <select
                                        name="current_program"
                                        id="current_program"
                                        className="selectpicker form-control form-control-sm"
                                       value={formData.current_program}
                                       disabled
                                    >
                                        <option>Select Course</option>
                                        {studentData.coursenames.data.results.map((course, index) => (
                                        <option key={index} value={course.id}>
                                            {course.coursename}
                                        </option>
                                        ))}
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
      <Form.Label>Academic Year:</Form.Label>
      <select
        name="academic_year"
        id="academic_year"
        className="selectpicker form-control form-control-sm"
        data-style="py-0"
        value={formData.academic_year}
       disabled
      >
        <option value="">Select</option>
        {generateYearOptions()}
      </select>
    </Form.Group>
                                
                                  <Form.Group className="col-md-12 form-group">
                                    <Form.Label>Current Year:</Form.Label>
                                    <select name="current_year" id="current_year" className="selectpicker form-control form-control-sm" data-style="py-0"  value={formData.current_year} disabled>
                                       <option>Select</option>
                                       <option>I</option>
                                       <option>II</option>
                                       <option >III</option>
                                       <option >IV</option>
                                    </select>
                                 </Form.Group>
                                 
                                                           
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
            <Col xl="9" lg="8">
    <Card>
        <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
                <h4 className="card-title">TC Information</h4>
            </div>
        </Card.Header>
        <Card.Body className="text-start">
            <div className="new-user-info">
                <Form className="form-horizontal" onSubmit={(e) => handleSubmit(e)}>
                    
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">TC Number:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_number" name="tc_number" placeholder="" value={postfromData.tc_number} onChange={handlePostChange} />
                            <input type="hidden" id="tc_studid" name="tc_studid" placeholder="" value={postfromData.tc_studid} onChange={handlePostChange}></input>
                           
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Name of the Student:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_studname" name="tc_studname" placeholder="" value={postfromData.tc_studname} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Name of the Parent / Guardian:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_guardian" name="tc_guardian" placeholder="" value={postfromData.tc_guardian} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Nationality & Religion:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_natrel" name="tc_natrel" placeholder="" value={postfromData.tc_natrel} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Community & Caste:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_caste" name="tc_caste" placeholder="" value={postfromData.tc_caste} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Date of Birth as entered in the Admission Register:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_dob" name="tc_dob" placeholder="" value={postfromData.tc_dob} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Gender:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_gender" name="tc_gender" placeholder="" value={postfromData.tc_gender} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="Bill_no">Date of Admission:</Form.Label>
                        <div className="col-md-6 mb-4" >
                            <input className="form-control form-control-sm" type="text" id="tc_doa" name="tc_doa" placeholder="" value={postfromData.tc_doa} onChange={handlePostChange} disabled/>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Course and Year to which the student was admitted : </Form.Label>
                        <div className="col-md-6 mb-4">
                            <select
                                name="tc_adcourse"
                                id="tc_adcourse"
                                className="selectpicker form-control form-control-sm"
                                value={postfromData.tc_adcourse}
                                disabled
                            >
                                <option>Select Course</option>
                                {studentData.coursenames.data.results.map((course, index) => (
                                <option key={index} value={course.id}>
                                    {course.coursename}
                                </option>
                                ))}
                            </select>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Course in which the student was studying at the time of leaving : </Form.Label>
                        <div className="col-md-6 mb-4">
                             <select
                                name="tc_course"
                                id="tc_course"
                                className="selectpicker form-control form-control-sm"
                                value={postfromData.tc_course}
                                disabled
                            >
                                <option>Select Course</option>
                                {studentData.coursenames.data.results.map((course, index) => (
                                <option key={index} value={course.id}>
                                    {course.coursename}
                                </option>
                                ))}
                            </select>
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Whether qualified for promotion to Higher Class :</Form.Label>
                        <div className="col-md-6 mb-4">
                            <input className="form-control form-control-sm" type="text" id="tc_qualified" name="tc_qualified" placeholder="" value={postfromData.tc_qualified} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Reason for leaving the Institution :</Form.Label>
                        <div className="col-md-6 mb-4">
                            <input className="form-control form-control-sm" type="text" id="tc_reason" name="tc_reason" placeholder="" value={postfromData.tc_reason} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Date on which the student actually left the Institution :</Form.Label>
                        <div className="col-md-6 mb-4">
                           
                                    <Flatpickr
                                       id="tc_releivingdate"
                                       name="tc_releivingdate"
                                       value={postfromData.tc_releivingdate}
                                       onChange={(selectedDates) =>
                                          handleDateChange(selectedDates, 'tc_releivingdate')
                                       }
                                       options={{
                                          dateFormat: 'd-m-Y', // Customize date format
                                          altInput: true, // Enable alternate input
                                          altFormat: 'd-m-Y', // Alternate input format
                                           maxDate: new Date(), // Show previous 1 week + future dates
                                       }}
                                       className="form-control" // Add any additional CSS classes here
                                    />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Date of issue of Transfer Certificate :</Form.Label>
                        <div className="col-md-6 mb-4">
                            <Flatpickr
                                       id="tc_date"
                                       name="tc_date"
                                       value={postfromData.tc_date}
                                       onChange={(selectedDates) =>
                                          handleDateChange(selectedDates, 'tc_date')
                                       }
                                       options={{
                                          dateFormat: 'd-m-Y', // Customize date format
                                          altInput: true, // Enable alternate input
                                          altFormat: 'd-m-Y', // Alternate input format
                                           maxDate: new Date(), // Show previous 1 week + future dates
                                       }}
                                       className="form-control" // Add any additional CSS classes here
                                    />
                        </div>
                    </Form.Group>
                    <Form.Group className="row align-items-center">
                        <Form.Label className="col-md-4 mb-4" htmlFor="last_name">Student's Conduct and Character :</Form.Label>
                        <div className="col-md-6 mb-4">
                            <input className="form-control form-control-sm" type="text" id="tc_conduct" name="tc_conduct" placeholder="" value={postfromData.tc_conduct} onChange={handlePostChange} />
                        </div>
                    </Form.Group>
                    <div className="text-center">
                        <Button type="submit" id="savebutton" variant="btn btn-primary" disabled={!isButtonEnabled}>Save</Button>
                    </div>
                </Form>
            </div>
        </Card.Body>
    </Card>
</Col>


            </Row>
            </div>
  );
}

export default TranscertAdd;
